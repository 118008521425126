import React from 'react';

import '../styles/basic-input.scss';

const BasicInput = props => {
  let { input, type, placeholder, meta, id, label, labelClass, disabled, className, t, transparent, hidden } = props;

  return (
    <div className={`basic-input ${className} ${transparent ? 'transparent' : ''} ${hidden ? 'hidden' : ''}`}>
      {label && (
        <label className={`input-label ${labelClass || ''}`} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={`input-wrapper ${meta && meta.error && meta.touched ? 'error' : ''}`}>
        <input
          {...input}
          id={id}
          readOnly={disabled}
          disabled={disabled}
          type={type || 'text'}
          placeholder={placeholder || ''}
          className={meta && meta.error && meta.touched ? 'invalid ' : ''}
          autoComplete="off"
        />
      </div>
      <div className={`error-message ${meta && meta.error && meta.touched ? 'visible' : ''}`}>{meta && meta.error && meta.touched && (t ? t(meta.error) : meta.error)}</div>
    </div>
  );
};
BasicInput.defaultProps = {
  disabled: false,
  className: '',
  transparent: false,
};

export default BasicInput;
