import { THEMES } from '../../../common/constants';

export const hoveredItems = {
  first: 0,
  second: 1,
  third: 2,
};

export const secondBannerTopMatrix = [
  ['.', { width: 10, height: 10 }],
  [{ width: 20, height: 10, backgroundColor: 'transparent' }, { width: 20, height: 20 }],
  [{ width: 20, height: 10, backgroundColor: 'transparent' }, { width: 35, height: 15 }],
  [
    { width: 20, height: 15, backgroundColor: 'transparent' },
    { width: 20, height: 15 },
    { width: 20, height: 15, backgroundColor: THEMES.standard.secondaryThemeColor },
    { width: 20, height: 15, backgroundColor: 'transparent' },
    { width: 10, height: 15, backgroundColor: THEMES.standard.secondaryThemeColor },
  ],
  [{ width: 40, height: 10, backgroundColor: 'transparent' }, { width: 20, height: 10, backgroundColor: THEMES.standard.secondaryThemeColor }],

  [{ width: 60, height: 10, backgroundColor: 'transparent' }, { width: 20, height: 20, backgroundColor: THEMES.standard.secondaryThemeColor }],
  ['.'],
  ['.'],
  [{ width: 120, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10, backgroundColor: THEMES.standard.secondaryThemeColor }],
  ['.'],
  ['.'],
  [{ width: 160, height: 10, backgroundColor: 'transparent' }, { width: 7, height: 7, backgroundColor: THEMES.standard.secondaryThemeColor }],
];

export const findOutMoreBottomMatrix = [
  [{ width: 6, height: 6 }],
  [{ width: 20, height: 50, backgroundColor: 'transparent' }],
  [{ width: 150, height: 6, backgroundColor: 'transparent' }, { width: 6, height: 6 }],
  [{ width: 20, height: 10, backgroundColor: 'transparent' }],
  [{ width: 60, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 20, height: 30, backgroundColor: 'transparent' }],
  [{ width: 100, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 175, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 205, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 220, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }, { width: 15, height: 15 }],
];

export const findOutMoreTopMatrix = [
  [{ width: 60, height: 15 }],
  [{ width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }, { width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 45, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }, { width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 170, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 170, height: 30, backgroundColor: 'transparent' }],
  [{ width: 190, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 170, height: 10, backgroundColor: 'transparent' }],
  [{ width: 90, height: 10, backgroundColor: 'transparent' }, { width: 6, height: 6 }],
  [{ width: 170, height: 40, backgroundColor: 'transparent' }],
  [{ width: 280, height: 10, backgroundColor: 'transparent' }, { width: 6, height: 6 }],
];
