import React from 'react';

import '../styles/checkbox.scss';

const CheckBox = props => {
  const { input, meta, id, disabled, text, t } = props;

  return (
    <div className="checkbox">
      <div className="chekbox-with-text">
        <div className="checkbox-wrapper">
          <input id={id} checked={input.value || false} disabled={disabled} {...input} type="checkbox" />
          <span className="checkmark" />
        </div>
        {text && <label className="checkbox-label f-tertiary ">{t ? t(text) : text}</label>}
      </div>
      <div className={`error-message ${meta && meta.error && meta.touched ? 'visible' : ''}`}>{meta && meta.error && (t ? t(meta.error) : meta.error)}</div>
    </div>
  );
};

export default CheckBox;
