import { THEMES } from '../../../common/constants';

export const fields = {
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'Email',
  phoneNumber: 'PhoneNumber',
  message: 'Message',
  company: 'Company',
  requestType: 'Status',
};

export const contactUsImageMatrix = [
  [{ width: 160, height: 18, backgroundColor: 'transparent' }, { width: 10, height: 10 }],
  [{ width: 110, height: 20, backgroundColor: 'transparent' }],
  [{ width: 110, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10 }],

  [{ width: 65, height: 18, backgroundColor: 'transparent' }, { width: 10, height: 10 }, { width: 18, height: 18, backgroundColor: 'transparent' }, { width: 18, height: 18 }],

  [{ width: 75, height: 18, backgroundColor: 'transparent' }, { width: 18, height: 18 }, { width: 18, height: 18, backgroundColor: 'transparent' }, { width: 10, height: 10, vAlign: 'top' }],
  [{ width: 55, height: 18, backgroundColor: 'transparent' }, { width: 56, height: 18 }],
  [{ width: 40, height: 17, backgroundColor: 'transparent' }, { width: 56, height: 17 }],
  [{ width: 40, height: 5, backgroundColor: 'transparent' }, { width: 17, height: 5 }],

  [{ width: 40, height: 10, backgroundColor: 'transparent' }, { width: 18, height: 18 }, { width: 36, height: 10, backgroundColor: 'transparent' }, { width: 18, height: 18 }],
  [{ width: 40, height: 10, backgroundColor: 'transparent' }, { width: 54, height: 18, backgroundColor: THEMES.standard.mapGrayColor }],
  [{ width: 58, height: 10, backgroundColor: 'transparent' }, { width: 18, height: 18, backgroundColor: THEMES.standard.mapGrayColor }],
  [{ width: 40, height: 10, backgroundColor: 'transparent' }, { width: 18, height: 18, backgroundColor: THEMES.standard.mapGrayColor }],
  [{ width: 29, height: 10, backgroundColor: 'transparent' }, { width: 11, height: 11, backgroundColor: THEMES.standard.mapGrayColor }],
  [{ width: 40, height: 10, backgroundColor: 'transparent' }, { width: 11, height: 11, backgroundColor: THEMES.standard.mapGrayColor }],
  [{ width: 20, height: 8, backgroundColor: 'transparent' }],
  [{ width: 11, height: 11, backgroundColor: THEMES.standard.mapGrayColor }],
];
