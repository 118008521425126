import React from 'react';
import PropTypes from 'prop-types';

import { FORMS } from '../../../common/constants';
import { fields } from '../constants/contact-us-constants';

import BasicInput from '../../../common/form/components/basic-input';
import TextArea from '../../../common/form/components/text-area';
import Button from '../../../common/form/components/button';
import Select from '../../../common/form/components/select';

import { validate } from '../validators/contact-us-validator';

import { Field, reduxForm } from 'redux-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import '../styles/contact-us-form.scss';
import 'react-phone-number-input/style.css';

let ContactUsForm = ({ handleSubmit, valid, requestTypeData, submitDisabled, phoneNumberValue, onPhoneInputChange }, { t }) => {
  return (
    <form className="contact-us-form" onSubmit={handleSubmit} noValidate>
      <Field
        id={fields.firstName}
        name={fields.firstName}
        component={BasicInput}
        className={'col-lg-6 col-md-6 col-sm-12'}
        type="text"
        placeholder={t('CONTACT_US_FORM.FIRST_NAME_PLACEHOLDER')}
        t={t}
      />
      <Field id={fields.lastName} name={fields.lastName} component={BasicInput} type="text" className={'col-lg-6 col-md-6 col-sm-12'} placeholder={t('CONTACT_US_FORM.LAST_NAME_PLACEHOLDER')} t={t} />
      <Field id={fields.email} name={fields.email} component={BasicInput} type="email" placeholder={t('CONTACT_US_FORM.EMAIL_PLACEHOLDER')} t={t} className="full-width" />
      <PhoneInput
        withCountryCallingCode
        countrySelectProps={{ unicodeFlags: true }}
        placeholder={t('CONTACT_US_FORM.PHONE_NUMBER_PLACEHOLDER')}
        value={phoneNumberValue}
        id={fields.phoneNumber}
        name={fields.phoneNumber}
        className="basic-input full-width"
        onChange={onPhoneInputChange}
        style={!phoneNumberValue || (phoneNumberValue && isValidPhoneNumber(phoneNumberValue)) ? { marginBottom: '20px' } : { marginBottom: '0' }}
      />
      {phoneNumberValue && !isValidPhoneNumber(phoneNumberValue) && <div className="error-message">{t('CONTACT_US.PHONE_NUMBER_INVALID')}</div>}
      <Field id={fields.company} name={fields.company} component={BasicInput} type="text" placeholder={t('CONTACT_US_FORM.COMPANY_PLACEHOLDER')} t={t} className="full-width" />
      <Field id={fields.requestType} name={fields.requestType} placeholder={t('CONTACT_US_FORM.REQUEST_TYPE')} component={Select} data={requestTypeData} />
      <Field id={fields.message} name={fields.message} component={TextArea} placeholder={t('CONTACT_US_FORM.MESSAGE_PLACEHOLDER')} t={t} className="full-width" />
      <Button className="btn-green btn-large" disabled={!valid || submitDisabled || !phoneNumberValue || (phoneNumberValue && !isValidPhoneNumber(phoneNumberValue))}>
        <p className="f-secondary">{t('CONTACT_US_FORM.SUBMIT')}</p>
      </Button>
    </form>
  );
};

ContactUsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

ContactUsForm = reduxForm({
  form: FORMS.contactUs,
  validate,
  enableReinitialize: true,
})(ContactUsForm);

export default ContactUsForm;
