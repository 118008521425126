import React, { useState, useEffect, useRef } from 'react';
import Footer from './app/shared/footer/components/footer';
import Header from './app/shared/header/components/header';
import Hubspot from './common/hubspot/components/hubspot';

import { history } from './core/routes';

import { HUBSPOT_PORTAL_ID, HUBSPOT_BASE_URL, G_TAG_CODE } from './common/constants';

import './common/styles/global-style.scss';

const App = ({ children, currentPath }) => {
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      currentPath: currentPath,
    })
  );

  const prevScrollY = useRef(0);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [hubspotVisible, setHubspotVisible] = useState(true);
  const [activeRoute, setActive] = useState(window.location.pathname);
  const container = useRef('');
  useEffect(
    () => {
      const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY < 0) {
          setHeaderVisible(true);
          return;
        }

        if (prevScrollY.current < currentScrollY && headerVisible) {
          setHeaderVisible(false);
        }
        if (prevScrollY.current > currentScrollY && !headerVisible) {
          setHeaderVisible(true);
        }

        prevScrollY.current = currentScrollY;
      };

      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => window.removeEventListener('scroll', handleScroll);
    },
    [headerVisible]
  );

  useEffect(() => {
    history.listen(({ pathname }) => {
      setHubspotVisible(false);

      setActive(pathname);

      setTimeout(() => {
        setHubspotVisible(true);
      }, 300);
    });
  });

  useEffect(
    () => {
      const gtag = window.gtag;
      if (gtag) {
        gtag('config', G_TAG_CODE, { page_path: activeRoute });
      }
      if (container && container.current) {
        container.current.scrollTop = 0;
      }
    },
    [activeRoute, container]
  );

  return (
    <div className="main-container" ref={container}>
      <Header sticky={true} headerVisible={true} activeRoute={activeRoute} />
      <div className="main-container__content">{childrenWithProps}</div>
      <Footer />
      {hubspotVisible && <Hubspot portalId={HUBSPOT_PORTAL_ID} baseUrl={HUBSPOT_BASE_URL} />}
    </div>
  );
};
export default App;
