import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { connect } from 'react-redux';

import { map } from 'lodash';

import MainBanner from '../../shared/main-banner/components/main-banner';
import WhitePaperForm from './white-paper-form';
import Crumbs from '../../shared/crumbs/components/crumbs';

import whitePaperImage from '../assets/white-paper-image.png';

import { FORMS } from '../../../common/constants';

import { whitePaperImageMatrix, interestOptions, fields } from '../constants/white-paper-constants';

import '../styles/white-paper.scss';

const WhitePaper = (props, { t }) => {
  const [messageVisible, changeVisibilty] = useState(false);

  const handleSubmit = vals => {
    changeVisibilty(true);

    setTimeout(() => {
      changeVisibilty(false);
      props.resetForm();
    }, 5000);
  };

  let options = map(interestOptions, item => ({ value: t(item.value), label: t(item.label) }));

  return (
    <div className="white-paper-container">
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        upperItems={
          <div className="upper visible-md visible-sm">
            <p className="f-tertiary title">{t('WHITE_PAPER.ABOVE_TITLE_TEXT')}</p>
            <div className="image">
              <Crumbs matrix={whitePaperImageMatrix} canvasPosition={{ top: -100, right: -50 }} />
              <img src={whitePaperImage} alt="" />
            </div>
          </div>
        }
        items={[
          [
            <p className="f-tertiary title invisible-sm invisible-md">{t('WHITE_PAPER.ABOVE_TITLE_TEXT')}</p>,
            <div className="image invisible-sm invisible-md">
              <Crumbs matrix={whitePaperImageMatrix} canvasPosition={{ top: -100, right: -50 }} />
              <img src={whitePaperImage} alt="" />
            </div>,
            <h3 className="f-primary bold form-title">{t('WHITE_PAPER.FORM_TITLE_TEXT')}</h3>,
            <p className="f-tertiary form-sub">{t('WHITE_PAPER.FORM_SUBTITLE_TEXT')}</p>,
            <WhitePaperForm
              onSubmit={handleSubmit}
              messageVisible={messageVisible}
              interestOptions={options}
              initialValues={{
                [fields.interest]: options[0],
              }}
            />,
            <p className={`submit-message ${!messageVisible ? 'hidden' : ''}`}>{t('WHITE_PAPER_FORM.SUBMIT_MESSAGE')}</p>,
          ],
          [
            <h1 className="f-primary bold article-title">{t('WHITE_PAPER.ARTICLE_TITLE')}</h1>,
            <p className="f-tertiary article-sub">{t('WHITE_PAPER.ARTICLE_TEXT_1')}</p>,
            <p className="f-tertiary article-sub mt">{t('WHITE_PAPER.ARTICLE_TEXT_2')}</p>,
            <p className="f-tertiary article-sub mt">{t('WHITE_PAPER.ARTICLE_TEXT_3')}</p>,
          ],
        ]}
        className="usual-banner first-banner"
      />
    </div>
  );
};

WhitePaper.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  resetForm: () => dispatch(reset(FORMS.whitePaper)),
});

export default connect(null, mapDispatchToProps)(WhitePaper);
