import React, { Fragment } from 'react';

import '../style/stepper.scss';

const Stepper = ({ stepperItems, className = '' }) => {
  return (
    <div className={`stepper ${className}`}>
      {stepperItems &&
        stepperItems.map((item, i) => {
          return (
            <div className="stepper-column" key={i} style={{ width: item.width, zIndex: stepperItems.length - 1 === i ? 0 : i }}>
              <div className="stepper-box" style={{ backgroundColor: item.color, backgroundImage: item.backgroundImage }}>
                {item.desc && <p className="f-secondary bold">{item.desc}</p>}
                {item.subDesc && <p className="f-secondary">{item.subDesc}</p>}
                {item.components &&
                  item.components.map((Component, index) => {
                    return <Fragment key={index}>{Component}</Fragment>;
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Stepper;
