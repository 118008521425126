import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import I18n from 'redux-i18n';

import Store from './core/store';
import Routes from './core/routes';

import { translations } from './core/translations';

// import initilizeHttpClient from './core/axios-config';

// initilizeHttpClient();

const StoreInstance = Store();

ReactDOM.render(
  <Provider store={StoreInstance}>
    <I18n translations={translations}>
      <Routes />
    </I18n>
  </Provider>,
  document.getElementById('root')
);

export default StoreInstance;
