import React from 'react';
import PropTypes from 'prop-types';

import '../styles/button.scss';

const Button = ({ children, disabled, type, className, onClick }) => {
  return (
    <button disabled={disabled} type={type || 'submit'} className={`btn ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

Button.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Button;
