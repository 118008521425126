import React from 'react';

import '../styles/blog-post.scss';

const BlogPost = ({ image, title, desc, button, className, WrapperComponent }) => {
  return WrapperComponent ? (
    <div className={`blog-post-wrapper ${className || ''}`}>
      <WrapperComponent>
        <div className={`blog-post`}>
          <div className="image" style={{ backgroundImage: `url("${image}")` }} />
          <div className="title">{title}</div>
          <div className="desc">{desc}</div>
          {button}
        </div>
      </WrapperComponent>
    </div>
  ) : (
    <div className={`blog-post-wrapper ${className || ''}`}>
      <div className={`blog-post`}>
        <div className="image" style={{ backgroundImage: `url("${image}")` }} />
        <div className="title">{title}</div>
        <div className="desc">{desc}</div>
        {button}
      </div>
    </div>
  );
};

export default BlogPost;
