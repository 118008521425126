import React from 'react';
import { isString } from 'lodash';

import '../style/crumbs.scss';

const Crumbs = ({
  matrix,
  defaultItItemWidth = 10,
  defaultItItemHeight = 10,
  canvasPosition = {
    top: 'unset',
    left: 'unset',
    bottom: 'unset',
    right: 'unset',
  },
}) => {
  return (
    <div className="crumbs" style={{ top: canvasPosition.top, left: canvasPosition.left, bottom: canvasPosition.bottom, right: canvasPosition.right }}>
      {matrix &&
        matrix.map((row, i) => {
          return (
            <div className="crumbs-row" key={i}>
              {row.map((item, index) => {
                let style = {};
                if (isString(item)) {
                  style = { width: `${defaultItItemWidth}px`, height: `${defaultItItemHeight}px`, backgroundColor: 'transparent' };
                } else if (!isString(item) && !item.image) {
                  style = {
                    width: item && item.width ? `${item.width}px` : `${defaultItItemWidth}px`,
                    height: item && item.height ? `${item.height}px` : `${defaultItItemHeight}px`,
                    backgroundColor: item.backgroundColor || 'white',
                    verticalAlign: item.vAlign,
                  };
                }
                return (
                  <div className="item" key={index} style={style}>
                    {item.image && <img src={item.image} alt="" style={{ width: item.width || 'auto ', height: item.height || 'auto' }} />}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default Crumbs;
