import standardThemeColors from './styles/theme-variables/css-variables.scss';

import linkedin from '../app/shared/footer/assets/linkedin.png';
import twitter from '../app/shared/footer/assets/twitter.png';

export const HUBSPOT_PORTAL_ID = '8772031';
export const HUBSPOT_BASE_URL = 'https://js.hs-scripts.com';
export const GOOGLE_API_KEY = 'AIzaSyAm6CHXBiungNUOEscRSdTnZSrFpe4WooQ';
export const HYBIRD_MEDIUM_URL = 'https://medium.com/hybird';
export const G_TAG_CODE = 'UA-144691189-1';

export const THEMES = {
  standard: {
    ...standardThemeColors,
  },
};

export const FORMS = {
  contactUs: 'contactUsForm',
  whitePaper: 'white-paper',
  subscribeForm: 'subscribe-form',
};

export const CONTACT_US_TYPES = {
  schedule: {
    type: 'schedule',
    label: 'Schedule a site survey',
  },
  speak: {
    type: 'speak',
    label: 'Speak with an expert',
  },
  request: {
    type: 'request',
    label: 'Request a demo',
  },
  somethingElse: {
    type: 'else',
    label: 'Something else',
  },
};

export const HYBIRD_DATA = {
  email: 'contact@hybirdtech.com',
  phone: '+44 (0) 207 267 7197',
};

export const SOCIAL_MEDIA_ICONS = [
  {
    icon: linkedin,
    name: 'FOOTER.LINKEDIN',
    link: 'https://www.linkedin.com/company/hybird-ltd/',
  },
  {
    icon: twitter,
    name: 'FOOTER.TWITTER',
    link: 'https://twitter.com/hybird_tech',
  },
];

export const EMAIL_REGEX = /^[a-zA-Z0-9._%\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
