import { fields } from '../constants/subscribe-form-constants';

import { EMAIL_REGEX } from '../../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[fields.email]) {
    errors[fields.email] = 'SUBSCRIBE.EMAIL_REQUIRED';
  }

  if (values[fields.email] && !EMAIL_REGEX.test(values[fields.email])) {
    errors[fields.email] = 'SUBSCRIBE.EMAIL_INVALID';
  }

  return errors;
};
