import React from 'react';
import PropTypes from 'prop-types';

import { FORMS } from '../../../../common/constants';
import { fields } from '../constants/subscribe-form-constants';

import BasicInput from '../../../../common/form/components/basic-input';
import Button from '../../../../common/form/components/button';

import { validate } from '../validators/subsctibe-validator';

import { Field, reduxForm } from 'redux-form';

let SubscribeForm = ({ handleSubmit, valid, submitDisabled }, { t }) => {
  return (
    <form className="subscribe-form" onSubmit={handleSubmit} noValidate>
      <Field id={fields.email} name={fields.email} className="col-sm-12" component={BasicInput} type="email" placeholder={t('SUBSCRIBE.EMAIL_PLACEHOLDER')} t={t} transparent={true} />

      <Button className="btn-green btn-small col-sm-12" disabled={!valid || submitDisabled}>
        <p className="f-secondary">{t('SUBSCRIBE.SUBMIT')}</p>
      </Button>
    </form>
  );
};

SubscribeForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SubscribeForm = reduxForm({
  form: FORMS.subscribeForm,
  validate,
  enableReinitialize: true,
})(SubscribeForm);

export default SubscribeForm;
