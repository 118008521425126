
import routesConstanst from '../../../../common/routes-constants';
import { THEMES } from '../../../../common/constants';

export const footerLinks = [
  {
    slug: 'FOOTER.PRIVACY_POLICY',
    link: routesConstanst.routes.unProtectedRoutes.privacy.fullPath,
  },
  {
    slug: 'FOOTER.TERMS_AND_CONDITIONS',
    link: routesConstanst.routes.unProtectedRoutes.terms.fullPath,
  },
];

export const footerMatrix = [
  [{ width: 20, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 20, height: 10, backgroundColor: 'transparent' }],
  [{ width: 45, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 55, height: 25, backgroundColor: 'transparent' }, { width: 50, height: 25 }, { width: 25, height: 25, backgroundColor: 'transparent' }, { width: 25, height: 25 }],
  [{ width: 95, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10 }],
  [{ width: 95, height: 10, backgroundColor: 'transparent' }],
  [{ width: 105, height: 25, backgroundColor: 'transparent' }, { width: 25, height: 25 }],
  [{ width: 95, height: 120, backgroundColor: 'transparent' }],
  [{ width: 95, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10 }],

  // [{ width: 60, height: 15 }],
  // [{ width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }, { width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  // [{ width: 45, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }, { width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  // [{ width: 170, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  // [{ width: 170, height: 30, backgroundColor: 'transparent' }],
  // [{ width: 190, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  // [{ width: 170, height: 10, backgroundColor: 'transparent' }],
  // [{ width: 90, height: 10, backgroundColor: 'transparent' }, { width: 6, height: 6 }],
  // [{ width: 170, height: 40, backgroundColor: 'transparent' }],
];
