import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { map } from 'lodash';

import MainBanner from '../../shared/main-banner/components/main-banner';
import ContactUsForm from './contact-us-form';
import Crumbs from '../../shared/crumbs/components/crumbs';

import { contactUsImageMatrix, fields } from '../constants/contact-us-constants';

import Map from '../../shared/maps/components/map';

import markers from '../constants/markers';

import { CONTACT_US_TYPES, FORMS } from '../../../common/constants';

import '../styles/contact-us.scss';

const ContactUs = (props, { t }) => {
  const [center] = useState({ lat: 28, lng: -30 });
  const [zoom] = useState(0);
  const [successMessageVisible, setSuccessMesage] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState(null);

  const requestType = props.location.query.rt;

  const handleSubmit = vals => {
    const { resetForm } = props;
    setSuccessMesage(true);

    setTimeout(() => {
      resetForm();
      setPhoneNumberValue(null);
      setSuccessMesage(false);
    }, 5000);
  };

  return (
    <div className="contact-us-container">
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <h1 className="f-primary bold">{t('CONTACT_US.TITLE')}</h1>,
            <p className="f-tertiary ">{t('CONTACT_US.DESC')}</p>,
            <ContactUsForm
              onSubmit={handleSubmit}
              initialValues={{
                [fields.requestType]:
                  requestType && CONTACT_US_TYPES[requestType]
                    ? { label: CONTACT_US_TYPES[requestType].label, value: CONTACT_US_TYPES[requestType].label }
                    : { label: CONTACT_US_TYPES.speak.label, value: CONTACT_US_TYPES.speak.label },
              }}
              submitDisabled={successMessageVisible}
              requestTypeData={map(CONTACT_US_TYPES, ({ label }) => {
                return {
                  value: label,
                  label,
                };
              })}
              phoneNumberValue={phoneNumberValue}
              onPhoneInputChange={setPhoneNumberValue}
            />,
            <p className="f-primary sc-message">{successMessageVisible ? t('CONTACT_US.SUCCESS_MESSAGE') : ''} </p>,
          ],
          [
            <div className="map">
              <Crumbs matrix={contactUsImageMatrix} canvasPosition={{ bottom: -95, left: -40 }} />
              <Map {...{ markers, center, zoom, yesIWantToUseGoogleMapApiInternals: true }} />
            </div>,
          ],
        ]}
        className="usual-banner first-banner"
      />
    </div>
  );
};

ContactUs.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  resetForm: () => dispatch(reset(FORMS.contactUs)),
});

export default connect(null, mapDispatchToProps)(ContactUs);
