import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import '../styles/select.scss';

const CustomSelect = (props, context) => {
  const { t } = context;
  let {
    input: { id, name, value },
    className,
    data,
    meta,
  } = props;

  const [customInput, setInputValue] = useState(value || {});
  const [expanded, setExpanded] = useState(false);
  const selectRef = useRef(null);

  const handleClickOutside = event => {
    if (selectRef && !selectRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`select ${className || ''}`} ref={selectRef}>
      <div className="select-wrapper">
        <select
          name={name}
          id={id}
          value={customInput.value}
          onChange={e => {
            setInputValue(e.target.value);
          }}
        >
          {data.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
        <div className="custom-select" onClick={() => setExpanded(!expanded)}>
          <div className="input noselect">
            {customInput.label}
            <span className={`chevron ${expanded ? 'top' : 'bottom'}`} />
          </div>
          <div className={`dropdown ${expanded ? 'expanded' : ''}`}>
            <div className="select-items">
              {data.map((item, index) => {
                return (
                  <div key={index} className={`item ${item.value === customInput.value ? 'selected' : ''}`} onClick={() => setInputValue(item)}>
                    <label className="f-primary pointer noselect"> {item.label}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={`error-message ${meta && meta.error && meta.touched ? 'visible' : ''}`}>{meta && meta.error && meta.touched && t(meta.error)}</div>
    </div>
  );
};

CustomSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

CustomSelect.defaultProps = {
  data: [],
};

CustomSelect.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.any,
      value: PropTypes.any,
      CustomComponent: PropTypes.func,
    })
  ).isRequired,
};

export default CustomSelect;
