import React from 'react';
import PropTypes from 'prop-types';

import '../style/menu.scss';

const Menu = ({ menuItems, activeRoute, onClick }, { t }) => {
  return (
    <div className="menu-container">
      {menuItems &&
        menuItems.map((item, index) => {
          return (
            <div className="item" key={index} onClick={() => onClick(item)}>
              <label className="placeholder">{t(item.slug)}</label>
              <label className={`main pointer noselect ${activeRoute === item.path ? 'active' : ''}`}>{t(item.slug)}</label>
            </div>
          );
        })}
      <div className="copyright">
        <p className="f-tertiary">{t('FOOTER.COPYRIGHT', { date: new Date().getFullYear() })}</p>
      </div>
    </div>
  );
};

Menu.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default Menu;
