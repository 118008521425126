import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { FORMS, HYBIRD_DATA, SOCIAL_MEDIA_ICONS } from '../../../../common/constants';
import { footerLinks, footerMatrix } from '../constanst/footer-constants';

import Helpers from '../../../../core/helpers';

import { ReactComponent as Logo } from '../../../../common/assets/logo-footer.svg';
import SubscribeForm from '../../subscribe-form/components/subscribe-form';
import Crumbs from '../../crumbs/components/crumbs';

import '../style/footer.scss';

const Footer = (props, { t }) => {
  const [showMessage, setMessage] = useState(false);

  const handleSubmit = vals => {
    const { resetForm } = props;
    setMessage(true);

    setTimeout(() => {
      resetForm();
      setMessage(false);
    }, 5000);
  };

  return (
    <Fragment>
      <div className="upper-footer">
        <div className="upper-footer-wrapper">
          <div className="left col-md-12">
            <div className="text">
              <p className="f-primary bold">{t('FOOTER.MESSAGE_LETTER_TEXT')}</p>
            </div>
            <div className="social-media">
              {SOCIAL_MEDIA_ICONS.map((item, index) => {
                return (
                  <div
                    className="social-media-item pointer"
                    key={index}
                    onClick={() => {
                      window.open(item.link);
                    }}
                  >
                    <div className="image" style={{ backgroundImage: `url("${item.icon}")` }} />
                    <p className="f-primary bold noselect invisible-sm">{t(item.name)}</p>
                  </div>
                );
              })}
              <p className="f-tertiary social-media-item noselect invisible-sm">{t('FOOTER.FOLLOW_US')}</p>
            </div>
          </div>
          <div className="middle col-md-12">
            <p className="f-tertiary title ">{t('FOOTER.MIDDLE.MAIN_TITLE')}</p>
            <div className="middle__items">
              <div>
                <p className="f-primary bold">{t('FOOTER.MIDDLE.TITLE_1')}</p>
                <label className="f-tertiary">{t('FOOTER.MIDDLE.TITLE_1_TEXT_1')}</label>
              </div>
              <div>
                <p className="f-primary bold">{t('FOOTER.MIDDLE.TITLE_2')}</p>
                <label className="f-tertiary">{t('FOOTER.MIDDLE.TITLE_2_TEXT_1')}</label>
              </div>
              <div>
                <p className="f-primary bold">{t('FOOTER.MIDDLE.TITLE_3')}</p>
                <label className="f-tertiary">{t('FOOTER.MIDDLE.TITLE_3_TEXT_1')}</label>
              </div>
              <div>
                <p className="f-primary bold">{t('FOOTER.MIDDLE.TITLE_4')}</p>
                <label className="f-tertiary">{t('FOOTER.MIDDLE.TITLE_4_TEXT_1')}</label>
              </div>
              <div>
                <p className="f-primary bold">{t('FOOTER.MIDDLE.TITLE_5')}</p>
                <label className="f-tertiary">{t('FOOTER.MIDDLE.TITLE_5_TEXT_1')}</label>
              </div>
            </div>
          </div>
          <div className="right col-md-12">
            <p className="f-tertiary title">{t('FOOTER.RIGHT.MAIN_TITLE')}</p>
            <a className="standard-link" href={`mailto:${HYBIRD_DATA.email}`}>
              <p className="f-primary bold">{`${HYBIRD_DATA.email}`}</p>
            </a>
            <p className="f-primary bold pt">{HYBIRD_DATA.phone}</p>
          </div>
        </div>
      </div>
      <div className="lower-footer">
        <div className="lower-footer-wrapper">
          <div className="upper">
            <div className="logo-wrapper col-md-12">
              <Logo />
            </div>
            <div className="subscribe-wrapper col-md-12">
              <p className="f-secondary">{t('SUBSCRIBE.TITLE')}</p>
              <SubscribeForm onSubmit={handleSubmit} submitDisabled={showMessage} />
              {showMessage && <p className="f-secondary message">{t('SUBSCRIBE.MESSAGE')}</p>}
            </div>
          </div>
          <div className="lower">
            <div className="left-side col-sm-12">
              <p className="f-tertiary">{t('FOOTER.COPYRIGHT', { date: new Date().getFullYear() })}</p>
              <Crumbs matrix={footerMatrix} canvasPosition={{ top: -30, left: 250 }} />
            </div>
            <div className="right-side col-sm-12">
              {footerLinks.map((item, index) => {
                return (
                  <p className="f-secondary pointer" key={index} onClick={() => Helpers.goTo(item.link)}>
                    {t(item.slug)}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Footer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  resetForm: () => dispatch(reset(FORMS.subscribeForm)),
});

export default connect(null, mapDispatchToProps)(Footer);
