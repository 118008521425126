import Home from '../app/home/components/home';
import Clarity from '../app/clarity/components/clarity';
import ContactUs from '../app/contact-us/components/contact-us';
import WhitePaper from '../app/white-paper/components/white-paper';
import ThreeDrm from '../app/3drm/components/three-drm';
import CaseStudy from '../app/case-study/components/case-study';
import Privacy from '../app/privacy/components/privacy';
import Terms from '../app/terms/components/terms';
import BackupPolicy from '../app/backup-policy/components/backup-policy';

const routes = {
  unProtectedRoutes: {
    home: {
      fullPath: '/',
      path: '/',
      component: Home,
    },
    clarity: {
      fullPath: '/clarity',
      path: '/clarity',
      component: Clarity,
    },
    contactUs: {
      fullPath: '/contact-us',
      path: '/contact-us',
      component: ContactUs,
    },
    whitePaper: {
      fullPath: '/white-paper',
      path: '/white-paper',
      component: WhitePaper,
    },
    threeDRM: {
      fullPath: '/3drm',
      path: '/3drm',
      component: ThreeDrm,
    },
    caseStudy: {
      fullPath: '/case-study',
      path: '/case-study',
      component: CaseStudy,
    },
    privacy: {
      fullPath: '/privacy-policy',
      path: '/privacy-policy',
      component: Privacy,
    },
    terms: {
      fullPath: '/terms-and-conditions',
      path: '/terms-and-conditions',
      component: Terms,
    },
    backupPolicy: {
      fullPath: '/backup-policy',
      path: '/backup-policy',
      component: BackupPolicy,
    },
  },
  protectedRoutes: {},
};

export default {
  routes,
};
