import React from 'react';
import PropTypes from 'prop-types';

import MainBanner from '../../shared/main-banner/components/main-banner';

import '../styles/privacy.scss';

const Privacy = (props, { t }) => {
  return (
    <div className="backup-policy-container">
      <MainBanner
        items={[
          [
            <h1 className="f-primary title">HYBIRD DATA BACKUP AND RECOVERY POLICY</h1>,
            <h3 className="f-primary">1. Purpose, scope, and users</h3>,
            <p className="f-primary mt-usual">The purpose of this policy is to ensure that backup copies are created at defined intervals and regularly tested.</p>,
            <p className="f-primary">This document applies to the entire Information Security Management System (ISMS) scope, and to all personal data processing activities.</p>,
            <p className="f-primary">Users of this document are employees of HyBird and Infrastructure departments.</p>,

            <h3 className="f-primary mt-usual">2. Reference documents</h3>,
            <ul className="mt-usual">
              <li>ISO/IEC 27001 standard, control A.12.3.1</li>
              <li>EU GDPR Article 32</li>
              <li>Information Security Policy</li>
            </ul>,
            <h3 className="f-primary mt-usual"> 3. Introduction</h3>,
            <p className="f-primary mt-usual">
              The company backup and recovery policy defines the objectives, accountability, and application of backup and recovery of data held in the technology environment of all HyBird company
              departments.
            </p>,

            <h3 className="f-primary mt-usual"> 4. Goals</h3>,
            <p className="f-primary mt-usual">The main goals of this policy are:</p>,
            <ul className="mt-usual">
              <li>To define and apply a clear backup and restore standard for all Company information systems.</li>
              <li>To define backup and recovery standards per data prioritization.</li>
              <li>To prevent the loss of data in the case of an accidental deletion or corruption of data, system failure, or disaster.</li>
              <li>To permit timely restoration of information and business processes, should such events occur.</li>
              <li>To manage secure backup and restoration processes and the media employed in the process.</li>
              <li>
                To set the retention periods of information contained within system level backups designed for recoverability and provide a point-in- time snapshot of information as it existed during
                the time-period defined by system backup policies.
              </li>
            </ul>,
            <p className="f-primary mt-usual">List of Services and controls that should apply the policy:</p>,
            <ul className="mt-usual">
              <li>
                Company file services:
                <ul>
                  <li>Confidential Company data.</li>
                  <li>Confidential customer data.</li>
                </ul>
              </li>
              <li>
                Company source control services:
                <ul>
                  <li>Intellectual property data.</li>
                </ul>
              </li>
              <li>
                Company configuration files:
                <ul>
                  <li>Network device configuration files (e.g. WiFi Router, WiFi Access Points, Company Firewall, Managed Switches, Routers.)</li>
                </ul>
              </li>
              <li>
                Company internal services:
                <ul>
                  <li>Critical services configurations.</li>
                  <li>Critical resources OS System states.</li>
                </ul>
              </li>
              <li>
                Customers’ production applications:
                <ul>
                  <li>Hosted application production deployments serving customers’ needs and holding customer’s data.</li>
                </ul>
              </li>
            </ul>,
            <h3 className="f-primary mt-usual"> 5. Principle</h3>,
            <p className="f-primary mt-usual">The following principles direct this policy:</p>,
            <ul className="mt-usual">
              <li>Performing proper backup, storage, and handling of data is necessary for all departments to achieve their objectives.</li>
              <li>Staff must accurately follow the policy and protect the availability, confidentiality, and integrity of data.</li>
            </ul>,
            <h3 className="f-primary mt-usual"> 6. Policy</h3>,
            <p className="f-primary mt-usual bold">Data must be protected by regular backups.</p>,
            <ul className="mt-usual">
              <li>Infrastructure Team: customers’ data and production environment configuration settings.</li>
              <li>Company IT: internal resources.</li>
            </ul>,
            <p className="f-primary mt-usual bold">Exceptions to the standard process must be approved by the CTO.</p>,
            <p className="f-primary mt-usual bold">All backup data must be stored encrypted with AES-256 symmetric encryption algorithm.</p>,
            <p className="f-primary mt-usual bold">
              Backup copies must be stored in an environmentally protected and access-controlled secure location offsite from the location of the originating asset.
            </p>,
            <p className="f-primary mt-usual">Stored copies must be stored with a short description that includes the following information:</p>,
            <ul className="mt-usual">
              <li>Backup date / Resource name / type of backup method (Full/Incremental)</li>
            </ul>,
            <p className="f-primary mt-usual bold">Stored copies of data must be made available upon authorised request.</p>,
            <p className="f-primary mt-usual bold">The request for stored data must be approved by an authorised person nominated by a Director/Manager in the appropriate department.</p>,
            <p className="f-primary mt-usual bold">Requests for stored data must include:</p>,
            <ul className="mt-usual">
              <li>
                A completed form that outlines the specifics of the request, including what copy is being requested, where and when the requester would like it delivered, and why they are requesting
                the copy.
              </li>
              <li>Acknowledgement that the backup copy will be returned or destroyed promptly upon completion of its use.</li>
              <li>Submission of a return receipt as evidence that the backup copy has been returned – this can be in the form of an email.</li>
            </ul>,
            <p className="f-primary mt-usual bold">
              The Infrastructure Operator shall develop procedures for the handling and storage of information in order to prevent unauthorised disclosure, misuse, or loss.
            </p>,
            <p className="f-primary mt-usual bold">A record of physical and logical movements of backup media must be maintained</p>,
            <p className="f-primary mt-usual bold">This includes the following information:</p>,
            <ul className="mt-usual">
              <li>All identification information relating to the requested copies.</li>
              <li>Purpose of the request.</li>
              <li>Information about person requesting the copy.</li>
              <li>Authorisation for the request.</li>
              <li>Where the copy will be held while it is out of storage.</li>
              <li>When the copy was released from storage.</li>
              <li>When the copy will be returned to storage.</li>
            </ul>,
            <p className="f-primary mt-usual bold">Special controls must be used to protect sensitive or critical information.</p>,
            <p className="f-primary mt-usual ">Where special controls are required, i.e. to protect sensitive or critical information, the following should be considered:</p>,
            <ul className="mt-usual">
              <li>Use of a secured container(s).</li>
              <li>Hand delivery of the backup.</li>
              <li>Tamper-evident packaging.</li>
              <li>In extreme cases, the delivery should be split and dispatched by separate routes.</li>
            </ul>,
            <p className="f-primary mt-usual">
              Backup copies must be maintained in accordance with Retention and Disposal Schedule for backup copies, or as stipulated by specific customer requirements.
            </p>,
            <p className="f-primary mt-usual">
              The schedule will determine the status of the information, as to whether it can be disposed, cycled back into production, or remain in archive storage.
            </p>,
            <p className="f-primary mt-usual bold">All backup media must be appropriately disposed of.</p>,
            <p className="f-primary mt-usual">Prior to retirement and disposal, IT will ensure that:</p>,
            <ul className="mt-usual">
              <li>The media no longer contains active backup images.</li>
              <li>The media’s current or former contents cannot be read or recovered by an unauthorised party.</li>
              <li>With all backup media, IT will ensure the physical destruction of media prior to disposal.</li>
            </ul>,
            <p className="f-primary mt-usual bold">Backup copies should periodically be tested for recovery capability</p>,
            <p className="f-primary mt-usual">
              All relevant department backups should be verified periodically, and a report created on its ability to recover data (relevant for Logical/Cloud based backup procedure).
            </p>,
            <p className="f-primary mt-usual">On a quarterly basis, log information generated from each backup job will be reviewed for the following purposes:</p>,
            <ul className="mt-usual">
              <li>To check for and correct errors.</li>
              <li>To monitor the duration of the backup job.</li>
              <li>To optimize backup performance where possible.</li>
            </ul>,
            <p className="f-primary mt-usual">IT and Infrastructure teams will identify problems and take corrective action to reduce any risks associated with failed backups.</p>,
            <ul className="mt-usual">
              <li>Random test restores will be done once every 6 months in order to verify that backups have been successful.</li>
              <li>IT will maintain records demonstrating the review of logs and test restores so as to demonstrate compliance with this policy for auditing purposes.</li>
            </ul>,
            <p className="f-primary mt-usual">Every quarter the Backup Operators shall report on its ability to recover data (relevant for physical storage media).</p>,
            <p className="f-primary mt-usual">The ability to recover data shall be measured by:</p>,
            <ul className="mt-usual">
              <li>Ability to retrieve backup media sample (copies).</li>
              <li>A backup recovery exercise.</li>
            </ul>,
            <p className="f-primary mt-usual">The ability to recover data shall be reported to the departments via the quarterly Directors reporting process.</p>,
            <h3 className="f-primary mt-usual">7. Responsibilities and frequency schedule</h3>,
            <h5 className="f-primary mt-usual bold">7.1. Company IT Department</h5>,
            <p className="f-primary mt-usual ">
              Company IT Department is responsible for backing up internally hosted Company information systems. The department should maintain the following backup schedule:
            </p>,
            <ul className="mt-usual">
              <li>
                Network file shares:
                <ul>
                  <li>Weekly Full backup</li>
                  <li>Daily Incremental backup</li>
                </ul>
              </li>
              <li>
                Source control:
                <ul>
                  <li>Weekly Full backup</li>
                  <li>Daily Incremental backup</li>
                </ul>
              </li>
              <li>
                Configuration files:
                <ul>
                  <li>Monthly Full backup</li>
                  <li>Relevant backup initiated by configuration changes.</li>
                </ul>
              </li>
              <li>
                Internal services and data (license server, etc.):
                <ul>
                  <li>Weekly Full backup</li>
                  <li>Daily Incremental backup</li>
                </ul>
              </li>
            </ul>,
            <h5 className="f-primary mt-usual bold">7.2. Infrastructure Team</h5>,
            <p className="f-primary mt-usual">
              The Infrastructure Team is responsible for backing up all Customer production environments. Infrastructure Team should maintain the following backup schedule:
            </p>,
            <ul className="mt-usual">
              <li>
                Core Production
                <ul>
                  <li>Backed up via Google Clouds SQL Automated Backups.</li>
                  <li>Backup retention period 7 days.</li>
                  <li>Effective combined backup retention period is 7 days.</li>
                  <li>Manual backups are also implemented with a retention period of infinity.</li>
                </ul>
              </li>
              <li>
                Perspective Production
                <ul>
                  <li>Hourly DB transaction log backup (binary logging enabled).</li>
                </ul>
              </li>
            </ul>,
          ],
        ]}
        className="usual-banner first-banner"
      />
    </div>
  );
};

Privacy.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Privacy;
