import { THEMES } from '../../../common/constants';

export const threeDrmMatrix = [
  [{ width: 12, height: 12 }],
  [{ width: 12, height: 25, backgroundColor: 'transparent' }, { width: 25, height: 25 }],
  [{ width: 12, height: 18, backgroundColor: 'transparent' }, { width: 25, height: 18 }, { width: 18, height: 18 }],
  [{ width: 12, height: 7, backgroundColor: 'transparent' }, { width: 25, height: 7 }],
  [
    { width: 37, height: 25, backgroundColor: 'transparent' },
    { width: 25, height: 25, backgroundColor: THEMES.standard.secondaryThemeColor },
    { width: 15, height: 25, backgroundColor: 'transparent' },
    { width: 12, height: 12, backgroundColor: THEMES.standard.secondaryThemeColor, vAlign: 'top' },
  ],
  [{ width: 62, height: 7, backgroundColor: 'transparent' }, { width: 19, height: 19, backgroundColor: THEMES.standard.secondaryThemeColor }],

  [{ width: 62, height: 30, backgroundColor: 'transparent' }],
  [{ width: 125, height: 7, backgroundColor: 'transparent' }, { width: 11, height: 11, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 62, height: 30, backgroundColor: 'transparent' }],
  [{ width: 180, height: 7, backgroundColor: 'transparent' }, { width: 7, height: 7, backgroundColor: THEMES.standard.secondaryThemeColor }],
];

export const stepperMatrix = baseColor => [
  [{ width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15, backgroundColor: baseColor }],
  [{ width: 15, height: 15, backgroundColor: baseColor }, { width: 15, height: 15, backgroundColor: 'transparent' }],
  [{ width: 15, height: 15, backgroundColor: baseColor }, { width: 15, height: 15, backgroundColor: baseColor }],
  [{ width: 15, height: 15, backgroundColor: baseColor }, { width: 15, height: 15, backgroundColor: 'transparent' }],
  [{ width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15, backgroundColor: baseColor }],
  [{ width: 15, height: 15, backgroundColor: baseColor }, { width: 15, height: 15, backgroundColor: 'transparent' }],
  [{ width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15, backgroundColor: baseColor }],
];
