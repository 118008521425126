import { fields } from '../constants/contact-us-constants';

import { EMAIL_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[fields.email]) {
    errors[fields.email] = 'CONTACT_US.EMAIL_REQUIRED';
  }

  if (values[fields.email] && !EMAIL_REGEX.test(values[fields.email])) {
    errors[fields.email] = 'CONTACT_US.EMAIL_INVALID';
  }

  if (!values[fields.firstName]) {
    errors[fields.firstName] = 'CONTACT_US.FIRST_NAME_REQUIRED';
  }
  if (!values[fields.lastName]) {
    errors[fields.lastName] = 'CONTACT_US.LAST_NAME_REQUIRED';
  }
  if (!values[fields.company]) {
    errors[fields.company] = 'CONTACT_US.COMPANY_REQUIRED';
  }
  if (!values[fields.message]) {
    errors[fields.message] = 'CONTACT_US.MESSAGE_REQUIRED';
  }

  return errors;
};
