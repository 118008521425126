import React from 'react';

import '../style/burger-menu.scss';

const BurgerMenu = props => {
  const { open, direction, events } = props;

  return (
    <div className={`burger-menu ${open ? 'open' : ''}`} {...events}>
      <div className={`nav-icon ${direction || 'right'} `}>
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};

export default BurgerMenu;
