import React from 'react';
import PropTypes from 'prop-types';

import MainBanner from '../../shared/main-banner/components/main-banner';

import { HYBIRD_DATA } from '../../../common/constants';

import '../styles/privacy.scss';

const Privacy = (props, { t }) => {
  return (
    <div className="terms-container">
      <MainBanner
        items={[
          [
            <h1 className="f-primary title">HyBird Ltd. Privacy Policy.</h1>,

            <p className="f-tertiary">HyBird Ltd. is committed to protecting your privacy and your personal data.</p>,
            <p className="f-tertiary mt-usual">
              This policy applies to visitors to our website and representatives of our Customers who build and manage data using the HyBird Ltd. platform. This privacy policy you tells how we look
              after personal data when you visit our website or use the HyBird Ltd.’s Services. It also tells you about your privacy rights and how the law protects you.
            </p>,
            <p className="f-primary bold underline mt-usual">Contact details</p>,
            <p className="f-tertiary">If you have any questions about this privacy policy or our privacy practices, here are our contact details:</p>,
            <div className="ml-usual mt-usual">
              <p className="f-tertiary">HyBird Ltd.</p>
              <p className="f-tertiary">Unit 4, 45 Holmes Road</p>
              <p className="f-tertiary">London</p>
              <p className="f-tertiary">NW5 3AN</p>
              <p className="f-tertiary">United Kingdom</p>
            </div>,
            <p className="f-tertiary mt-usual">
              You have the right to make a complaint at any time to the Information Commissioner&#39;s Office (ICO), the UK supervisory authority for data protection issues ( www.ico.org.uk). We
              would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
            </p>,
            <p className="f-primary bold underline mt-usual">The data we collect about you</p>,
            <p className="f-tertiary">
              Personal data means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).
            </p>,
            <p className="f-tertiary mt-usual">We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>,
            <ul>
              <li>
                <em className="f-primary"> Identity Data</em>
                <p className="f-tertiary"> includes names, usernames or similar identifiers and titles.</p>
              </li>
              <li>
                <em className="f-primary"> Contact Data</em>
                <p className="f-tertiary"> includes billing address, email address and telephone numbers.</p>
              </li>
              <li>
                <em className="f-primary">Financial Data</em>
                <p className="f-tertiary"> includes bank account and payment card details.</p>
              </li>
              <li>
                <em className="f-primary"> Transaction Data</em>
                <p className="f-tertiary"> includes details about payments to and from you and other details of the services you have purchased from us.</p>
              </li>
              <li>
                <em className="f-primary"> Technical Data </em>
                <p className="f-tertiary">
                  includes internet protocol (IP) addresses, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and
                  platform, and other technology on the devices you use to access this website.
                </p>
              </li>
              <li>
                <em className="f-primary">Profile Data </em>
                <p className="f-tertiary">  includes your username and password, purchases or orders made by you, your preferences, feedback and survey responses.</p>
              </li>
              <li>
                <em className="f-primary">Usage Data </em>
                <p className="f-tertiary">includes information about how you use our website, and our services.</p>
              </li>
              <li>
                <em className="f-primary"> Marketing and Communications Data </em>
                <p className="f-tertiary">
                    includes your preferences in receiving marketing from us and our third parties and your communication preferences. We also collect, use and share Aggregated Data such as
                  statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will  not  directly
                  or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website or Service feature. However, if we
                  combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in
                  accordance with this privacy policy.
                </p>
              </li>
            </ul>,
            <p className="f-tertiary mt-usual">
              We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
              political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.
            </p>,
            <p className="f-tertiary mt-usual">HyBird Ltd. is not intended for children and we do not knowingly collect data relating to children.</p>,
            <p className="f-primary bold underline mt-usual"> If you don't provide personal data </p>,
            <p className="f-tertiary">
              Where we need to collect personal data by law, or under the terms of a contract we have with you or your organization, and you fail to provide that data when requested, we may not be
              able to perform the contract we have or are trying to enter into with you (for example, to provide you or your organization with HyBird Ltd.'s services). In this case, we may not be able
              to provide the HyBird Ltd. service to you or your organization.
            </p>,
            <p className="f-primary bold underline mt-usual"> The purposes for which we process your personal data </p>,
            <ul>
              <li>
                <p className="f-tertiary">Register you or your organisation as a Customer</p>
              </li>
              <li>
                <p className="f-tertiary">To provide services through the HyBird Ltd. platform to our Customers</p>
              </li>
              <li>
                <p className="f-tertiary">To manage payments, fees and charges and to collect and recover money owed to us</p>
              </li>
              <li>
                <p className="f-tertiary">
                  To manage our relationship with you and your organisation, including providing information about the HyBird Ltd. platform, how you and others inside and outside your organisation are
                  using the HyBird Ltd. platform, providing customer support and training, notifying you and your organisation about changes to our terms, pricing and privacy policy and asking you to
                  give feedback or participate in a survey
                </p>
              </li>
              <li>
                <p className="f-tertiary">
                  To administer and protect our business, this website and the HyBird Ltd. platform (including troubleshooting, data analysis, testing, system maintenance, support, reporting and
                  hosting of data)
                </p>
              </li>
              <li>
                <p className="f-tertiary">To deliver relevant website content and measure or understand the effectiveness of our website and our marketing</p>
              </li>
              <li>
                <p className="f-tertiary">To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</p>
              </li>
            </ul>,
            <p className="f-primary bold underline mt-usual"> The legal basis for processing </p>,
            <p className="f-tertiary"> We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances: </p>,
            <ul className="mt-usual">
              <li>
                <p className="f-tertiary">Where we have your consent. You have the right to withdraw consent at any time by contacting us</p>
              </li>
              <li>
                <p className="f-tertiary">Where we need to perform the contract we are about to enter into or have entered into with you</p>
              </li>
              <li>
                <p className="f-tertiary">Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests</p>
              </li>
              <li>
                <p className="f-tertiary">
                  Where it is necessary for the legitimate interests of a third party and your interests and fundamental rights do not override those interests. Third parties are likely to be your
                  organisation where they are our Customer
                </p>
              </li>
              <li>
                <p className="f-tertiary">
                  Where we rely on our legitimate interests this means in running our business effectively and improving the services which we offer (which includes obtaining payment for services,
                  keeping our records up to date, understanding how Customers use our services, to understand the Customers for our products and services, to keep our website updated and relevant, to
                  develop our business and to inform our marketing strategy
                </p>
              </li>
              <li>
                <p className="f-tertiary">Where we rely on the legitimate interests a third party this means their interest in receiving an effective service from HyBird Ltd.</p>
              </li>
            </ul>,
            <p className="f-primary bold underline mt-usual">Marketing</p>,
            <p className="f-primary bold underline mt-usual">Promotional offers from us</p>,
            <p className="f-tertiary mt-usual">
              We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which
              products, services and offers may be relevant for you (we call this marketing).
            </p>,
            <p className="f-tertiary mt-usual">
              You will receive marketing communications from us if you have requested information from us or products or services from us and you have not opted out of receiving that marketing.
            </p>,
            <p className="f-primary bold underline mt-usual">Third-party marketing</p>,
            <p className="f-tertiary">We will not share your personal data with any third party for marketing purposes.</p>,

            <p className="f-primary bold underline mt-usual">Opting out</p>,
            <p className="f-tertiary">
              You can ask us to stop sending you marketing messages at any time by following the opt- out links on any marketing message sent to you or by contacting us at any time.
            </p>,
            <p className="f-tertiary mt-usual">
              Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us because of a product/service purchase or other transactions.
            </p>,
            <p className="f-primary bold underline mt-usual">Cookies</p>,
            <p className="f-tertiary">
              You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this
              website may become inaccessible or not function properly.
            </p>,
            <p className="f-primary bold mt-usual ml-usual">We use the following cookies:</p>,
            <ul className="ml-usual">
              <li>
                <p className="f-tertiary">
                  Strictly necessary cookies: These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our
                  website, use a shopping cart or make use of e-billing services.
                </p>
              </li>
              <li>
                <p className="f-tertiary">
                  Analytical/performance cookies: They allow us to recognise and count the number of visitors and to see how visitors move around our website or Services and when they are using it.
                  This helps us to improve the way our website or Service works, for example, by ensuring that users are finding what they are looking for easily.
                </p>
              </li>
              <li>
                <p className="f-tertiary">
                  Functionality cookies: These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your
                  preferences (for example, your choice of language or region).
                </p>
              </li>
            </ul>,
            <p className="f-tertiary mt-usual">
              You can disable cookies by changing the settings on your browser. However, if you use your browser settings to block cookies you may not be able to access all or parts of our site.
            </p>,
            <p className="f-tertiary mt-usual">By continuing to use our website without changing your settings, you consent to our use of cookies as described above.</p>,
            <p className="f-primary bold underline mt-usual">Disclosures of your personal data</p>,
            <p className="f-tertiary">We may share your personal data with third parties who provide IT infrastructure and administration services to us.</p>,
            <p className="f-tertiary mt-usual">
              We may also share your personal data with third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other
              businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.
            </p>,
            <p className="f-tertiary mt-usual">
              We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your
              personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
            </p>,
            <p className="f-primary bold underline mt-usual">How long will you use my personal data for?</p>,
            <p className="f-tertiary">
              We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory,
              tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation
              in respect to our relationship with you.
            </p>,
            <p className="f-tertiary mt-usual">
              To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal,
              regulatory, tax, accounting or other requirements.
            </p>,
            <p className="f-tertiary mt-usual">
              Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us by contacting us.
            </p>,
            <p className="f-primary bold underline mt-usual">Your legal rights</p>,
            <p className="f-tertiary ">Under certain circumstances, you have rights under data protection laws in relation to your personal data. You have the right to:</p>,
            <ul className="mt-usual">
              <li>
                <em className="f-primary">Request access </em>
                <p className="f-tertiary">
                   to your personal data (commonly known as a &quot;data subject access request&quot;). This enables you to receive a copy of the personal data we hold about you and to check that we
                  are lawfully processing it.
                </p>
              </li>

              <li>
                <em className="f-primary">Request correction </em>
                <p className="f-tertiary">
                   of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the
                  new data you provide to us.
                </p>
              </li>
              <li>
                <em className="f-primary">Request erasure </em>
                <p className="f-tertiary">
                   of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us
                  to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully
                  or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal
                  reasons which will be notified to you, if applicable, at the time of your request.
                </p>
              </li>
              <li>
                <em className="f-primary">Object to processing </em>
                <p className="f-tertiary">
                    of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to
                  object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for
                  direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
                </p>
              </li>
              <li>
                <em className="f-primary">Request restriction </em>
                <p className="f-tertiary"> of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:</p>
                <ul className="ml-usual">
                  <li>
                    <p className="f-tertiary">If you want us to establish the data&#39;s accuracy.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">Where our use of the data is unlawful, but you do not want us to erase it.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">You have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.</p>
                  </li>
                </ul>
              </li>
              <li>
                <em className="f-primary">Request the transfer </em>
                <p className="f-tertiary">
                   of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable
                  format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                </p>
              </li>
              <li>
                <em className="f-primary"> Withdraw consent at any time </em>
                <p className="f-tertiary">
                   where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you
                  withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent. If you wish to
                  exercise any of the rights set out above, please contact us.
                </p>
              </li>
            </ul>,
            <p className="f-primary bold underline mt-usual"> No fee usually required </p>,
            <p className="f-tertiary">
              You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded,
              repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
            </p>,
            <p className="f-primary bold underline mt-usual"> What we may need from you </p>,
            <p className="f-tertiary">
              We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This
              is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation
              to your request to speed up our response.
            </p>,
            <p className="f-primary bold underline mt-usual"> Time limit to respond</p>,
            <p className="f-tertiary">
              We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of
              requests. In this case, we will notify you and keep you updated.
            </p>,
            <p className="f-tertiary mt-usual">
              If you would like to get in touch, please send us an email on&nbsp;
              <a className="standard-link" href={`mailto:${HYBIRD_DATA.email}`}>{`${HYBIRD_DATA.email}`}</a>
            </p>,
          ],
        ]}
        className="usual-banner first-banner"
      />
    </div>
  );
};

Privacy.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Privacy;
