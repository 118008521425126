import React from 'react';
import PropTypes from 'prop-types';

import { FORMS } from '../../../common/constants';
import { fields } from '../constants/white-paper-constants';

import BasicInput from '../../../common/form/components/basic-input';
import Button from '../../../common/form/components/button';
import CheckBox from '../../../common/form/components/checkbox';
import Select from '../../../common/form/components/select';

import { validate } from '../validators/white-paper-validators';

import { Field, reduxForm } from 'redux-form';

import '../styles/white-paper-form.scss';

let WhitePaperForm = ({ handleSubmit, valid, messageVisible, interestOptions }, { t }) => {
  return (
    <form className="white-paper-form" onSubmit={handleSubmit} noValidate>
      <Field
        id={fields.firstName}
        name={fields.firstName}
        className={'col-lg-6 col-md-6 col-sm-12'}
        component={BasicInput}
        type="text"
        placeholder={t('WHITE_PAPER_FORM.FIRST_NAME_PLACEHOLDER')}
        t={t}
      />
      <Field id={fields.lastName} name={fields.lastName} className={'col-lg-6 col-md-6 col-sm-12'} component={BasicInput} type="text" placeholder={t('WHITE_PAPER_FORM.LAST_NAME_PLACEHOLDER')} t={t} />
      <Field id={fields.email} name={fields.email} component={BasicInput} type="email" placeholder={t('WHITE_PAPER_FORM.EMAIL_PLACEHOLDER')} t={t} className="full-width" />

      <Field id={fields.companyName} name={fields.companyName} component={BasicInput} placeholder={t('WHITE_PAPER_FORM.COMPANY_PLACEHOLDER')} t={t} className="full-width" />
      <Field id={fields.role} name={fields.role} component={BasicInput} placeholder={t('WHITE_PAPER_FORM.ROLE_PLACEHOLDER')} t={t} className="full-width" />
      <Field id={fields.sector} name={fields.sector} component={BasicInput} placeholder={t('WHITE_PAPER_FORM.SECTOR_PLACEHOLDER')} t={t} className="full-width" />
      <Field id={fields.howDidYouHearAboutUs} name={fields.howDidYouHearAboutUs} component={BasicInput} placeholder={t('WHITE_PAPER_FORM.HOW_DID_YOU_HEAR_PLACEHOLDER')} t={t} className="full-width" />

      <p className="f-primary form-label-sub">{t('WHITE_PAPER_FORM.WHY_ARE_YOU_INTERESTED')}</p>
      <Field id={fields.interest} name={fields.interest} placeholder={t('WHITE_PAPER_FORM.INTEREST')} component={Select} data={interestOptions} />

      <p className="f-primary form-label-sub">{t('WHITE_PAPER_FORM.MARKETING_UPDATES')}</p>
      <Field id={fields.marketingUpdates} name={fields.marketingUpdates} component={CheckBox} text={'WHITE_PAPER_FORM.MARKETING_UPDATES_CHECKBOX_TEXT'} t={t} />

      <p className="f-primary form-label-sub">{t('WHITE_PAPER_FORM.PRIVACY')}</p>
      <Field id={fields.privacy} name={fields.privacy} component={CheckBox} text={'WHITE_PAPER_FORM.PRIVACY_CHECKBOX_TEXT'} t={t} />
      <Button className="btn-green btn-large" disabled={!valid || messageVisible}>
        <p className="f-secondary">{t('WHITE_PAPER_FORM.SUBMIT')}</p>
      </Button>
    </form>
  );
};

WhitePaperForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

WhitePaperForm = reduxForm({
  form: FORMS.whitePaper,
  validate,
  enableReinitialize: true,
})(WhitePaperForm);

export default WhitePaperForm;
