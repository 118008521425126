import React from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';
import MainBanner from '../../shared/main-banner/components/main-banner';
import Crumbs from '../../shared/crumbs/components/crumbs';
import Button from '../../../common/form/components/button';
import BlogPost from '../../shared/blog-post/components/blog-post';

import { ReactComponent as Icon1 } from '../assets/icon1.svg';
import { ReactComponent as Icon2 } from '../assets/icon2.svg';
import { ReactComponent as Icon3 } from '../assets/icon3.svg';
import { ReactComponent as Icon4 } from '../assets/icon4.svg';
import { ReactComponent as Icon5 } from '../assets/icon5.svg';
import { ReactComponent as Icon6 } from '../assets/icon6.svg';

import secondBannerImage from '../assets/main-image.png';

import blogImage1 from '../assets/blog-1.png';
import blogImage2 from '../assets/blog-2.png';
import blogImage3 from '../assets/blog-3.png';

import fourthBannerImage from '../assets/fourth_banner_image.png';

import fourthBannerBC from '../assets/BC.png';
import fourthBannerKM from '../assets/KM.png';
import fourthBannerCR from '../assets/CR.png';
import fourthBannerRC from '../assets/RC.png';
import fourthBannerDT from '../assets/DT.png';

import sixthBannerImage from '../assets/long-image-1.png';

import sixthBannerImage1 from '../assets/image-1.png';
import sixthBannerImage2 from '../assets/image-2.png';
import sixthBannerImage3 from '../assets/image-3.png';
import sixthBannerImage4 from '../assets/image-4.png';

import {
  secondBannerTopMatrix,
  secondBannerBottomMatrix,
  blogBannerMatrixBottom,
  blogBannerMatrixTop,
  blogLinks,
  sixthBannerTopMatrix,
  sixthBannerBottomMatrix,
  fourthBannerMatrix,
} from '../constants/home-constants';

import { HYBIRD_MEDIUM_URL } from '../../../common/constants';

import Helpers from '../../../core/helpers';
import routesConstants from '../../../common/routes-constants';

import '../styles/home.scss';

const Home = (props, { t }) => {
  return (
    <div className="home-container">
      <MainBanner
        classDefinitions={['col-md-12', 'col-md-12']}
        items={[
          [
            <Fade>
              <p className="f-tertiary">{t('HOME.FIRST_BANNER.TEXT_1')}</p>
              <h1 className="f-secondary bold">{t('HOME.FIRST_BANNER.TEXT_2')}</h1>
              <p className="f-tertiary">{t('HOME.FIRST_BANNER.TEXT_3')}</p>
            </Fade>,
            <Fade bottom>
              <div className="buttons">
                {/* <Button className="btn-success btn-small">
                <p className="f-secondary">{t('HOME.FIRST_BANNER.BUTTON_TEXT_1')}</p>
              </Button> */}
                <Button onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.clarity.fullPath)} className="btn-green btn-small">
                  <p className="f-secondary">{t('HOME.FIRST_BANNER.BUTTON_TEXT_2')}</p>
                </Button>
              </div>
            </Fade>,
          ],
          [
            <Fade>
              <iframe
                title="youtube"
                height="400"
                src="https://www.youtube-nocookie.com/embed/UQoz31r7L9c?rel=0"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Fade>,
          ],
        ]}
        className={'usual-banner first-banner'}
      />
      <MainBanner
        classDefinitions={['col-md-12', 'col-md-12']}
        items={[
          [
            <Fade top>
              <p className="f-tertiary">{t('HOME.SECOND_BANNER.TEXT_1')}</p>
            </Fade>,
            <Fade left>
              <h1 className="f-primary bold">{t('HOME.SECOND_BANNER.TEXT_2')}</h1>
            </Fade>,
            <Fade left>
              <h4 className="f-primary">{t('HOME.SECOND_BANNER.TEXT_3')}</h4>
            </Fade>,
            <Fade left>
              <p className="f-tertiary">{t('HOME.SECOND_BANNER.TEXT_4')}</p>
            </Fade>,
            <Fade bottom>
              <div className="buttons">
                <Button className="btn-transparent dark btn-small" type={'button'} onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.caseStudy.fullPath)}>
                  <p className="f-primary">{t('HOME.SECOND_BANNER.BUTTON_TEXT_1')}</p>
                </Button>
              </div>
            </Fade>,
          ],
          [
            <div className="image">
              <div className="image-wrapper">
                <img src={secondBannerImage} alt="" className="responsive-image" />
                <Crumbs matrix={secondBannerTopMatrix} canvasPosition={{ top: -50, left: -20 }} />
                <Crumbs matrix={secondBannerBottomMatrix} canvasPosition={{ bottom: -70.5, left: -40 }} />
              </div>
            </div>,
          ],
        ]}
        className={'usual-banner second-banner'}
      />
      <MainBanner
        upperItems={<h1 className="f-primary bold">{t('HOME.THIRD_BANNER.TITLE')}</h1>}
        items={[
          [
            <Fade>
              <div className="icon-wrapper col-md-6 col-sm-12">
                <div className="icon">
                  <Icon1 />
                </div>
                <h4 className="f-primary bold noselect">{t('HOME.THIRD_BANNER.ICON_1_TEXT')}</h4>
              </div>
            </Fade>,
            <Fade delay={200}>
              <div className="icon-wrapper col-md-6 col-sm-12">
                <div className="icon">
                  <Icon6 />
                </div>
                <h4 className="f-primary bold noselect">{t('HOME.THIRD_BANNER.ICON_6_TEXT')}</h4>
              </div>
            </Fade>,
            <Fade delay={400}>
              <div className="icon-wrapper col-md-6 col-sm-12">
                <div className="icon">
                  <Icon2 />
                </div>
                <h4 className="f-primary bold noselect">{t('HOME.THIRD_BANNER.ICON_2_TEXT')}</h4>
              </div>
            </Fade>,
            <Fade delay={600}>
              <div className="icon-wrapper col-md-6 col-sm-12">
                <div className="icon">
                  <Icon5 />
                </div>
                <h4 className="f-primary bold noselect">{t('HOME.THIRD_BANNER.ICON_5_TEXT')}</h4>
              </div>
            </Fade>,
            <Fade delay={800}>
              <div className="icon-wrapper col-md-6 col-sm-12">
                <div className="icon">
                  <Icon3 />
                </div>
                <h4 className="f-primary bold noselect">{t('HOME.THIRD_BANNER.ICON_3_TEXT')}</h4>
              </div>
            </Fade>,
            <Fade delay={1000}>
              <div className="icon-wrapper col-md-6 col-sm-12">
                <div className="icon">
                  <Icon4 />
                </div>
                <h4 className="f-primary bold noselect">{t('HOME.THIRD_BANNER.ICON_4_TEXT')}</h4>
              </div>
            </Fade>,
          ],
        ]}
        className={'usual-banner third-banner'}
      />

      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <div className="image-wrapper">
              <div className="image">
                <Crumbs matrix={sixthBannerTopMatrix} canvasPosition={{ top: -125, left: 135 }} />
                <img src={sixthBannerImage} alt="" className="responsive-image" />
                <Crumbs matrix={sixthBannerBottomMatrix} canvasPosition={{ bottom: -115, left: 55 }} />
              </div>
            </div>,
          ],
          [
            <Fade top>
              <p className="f-tertiary">{t('HOME.SIXTH_BANNER.TEXT_1')}</p>
            </Fade>,
            <Fade right>
              <h1 className="f-primary text-desc bold">{t('HOME.SIXTH_BANNER.TEXT_2')}</h1>
            </Fade>,
            <Fade right>
              <p className="f-tertiary">{t('HOME.SIXTH_BANNER.TEXT_3')}</p>
            </Fade>,
            <Fade bottom>
              <Button onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.clarity.fullPath)} className="btn-transparent dark btn-medium">
                <p className="f-primary">{t('HOME.SIXTH_BANNER.BTN_TEXT_1')}</p>
              </Button>
            </Fade>,
          ],
        ]}
        className={'usual-banner sixth-banner'}
      />

      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <Fade top>
              <p className="f-tertiary">{t('HOME.SEVENTH_BANNER.TEXT_1')}</p>
            </Fade>,
            <Fade left>
              <h1 className="f-primary bold">{t('HOME.SEVENTH_BANNER.TEXT_2')}</h1>
            </Fade>,
            <Fade left>
              <p className="f-tertiary">{t('HOME.SEVENTH_BANNER.TEXT_3')}</p>
            </Fade>,
            <Fade bottom>
              <div className="buttons">
                <Button onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.threeDRM.fullPath)} className="btn-transparent dark btn-small">
                  <p className="f-primary">{t('HOME.SIXTH_BANNER.BUTTON_TEXT_1')}</p>
                </Button>
              </div>
            </Fade>,
          ],
          [
            <div className="image" style={{ position: 'relative' }}>
              <div className="lower-items">
                <div className="images">
                  <div className="image-wrapper">
                    <Fade right>
                      <img src={sixthBannerImage1} alt="" />
                    </Fade>
                  </div>{' '}
                  <div className="image-wrapper">
                    <Fade delay={200} right>
                      <img src={sixthBannerImage2} alt="" />
                    </Fade>
                  </div>{' '}
                  <div className="image-wrapper">
                    <Fade delay={400} right>
                      <img src={sixthBannerImage3} alt="" />
                    </Fade>
                  </div>{' '}
                  <div className="image-wrapper">
                    <Fade delay={600} right>
                      <img src={sixthBannerImage4} alt="" />
                    </Fade>
                  </div>
                </div>
              </div>
            </div>,
          ],
        ]}
        className={'usual-banner seventh-banner'}
      />

      <MainBanner
        upperItems={
          <>
            <Crumbs matrix={fourthBannerMatrix} canvasPosition={{ top: 0, left: '50%' }} />
            <h1 className="f-primary bold">{t('HOME.FOURTH_BANNER.TITLE')}</h1>
          </>
        }
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [<img src={fourthBannerImage} alt="" />],
          [
            <Fade right>
              <div className="box">
                <img src={fourthBannerBC} alt={t('HOME.FOURTH_BANNER.TEXT_1_TITLE')} />
                <div>
                  <p className="f-primary bold">{t('HOME.FOURTH_BANNER.TEXT_1_TITLE')}</p>
                  <label className="f-tertiary text-desc">{t('HOME.FOURTH_BANNER.TEXT_1_DESC')}</label>
                </div>
              </div>
            </Fade>,
            <Fade right delay={200}>
              <div className="box">
                <img src={fourthBannerKM} alt={t('HOME.FOURTH_BANNER.TEXT_2_TITLE')} />
                <div>
                  <p className="f-primary bold">{t('HOME.FOURTH_BANNER.TEXT_2_TITLE')}</p>
                  <label className="f-tertiary text-desc">{t('HOME.FOURTH_BANNER.TEXT_2_DESC')}</label>
                </div>
              </div>
            </Fade>,
            <Fade right delay={400}>
              <div className="box">
                <img src={fourthBannerCR} alt={t('HOME.FOURTH_BANNER.TEXT_3_TITLE')} />
                <div>
                  <p className="f-primary bold">{t('HOME.FOURTH_BANNER.TEXT_3_TITLE')}</p>
                  <label className="f-tertiary text-desc">{t('HOME.FOURTH_BANNER.TEXT_3_DESC')}</label>
                </div>
              </div>
            </Fade>,
            <Fade right delay={600}>
              <div className="box">
                <img src={fourthBannerRC} alt={t('HOME.FOURTH_BANNER.TEXT_4_TITLE')} />
                <div>
                  <p className="f-primary bold">{t('HOME.FOURTH_BANNER.TEXT_4_TITLE')}</p>
                  <label className="f-tertiary text-desc">{t('HOME.FOURTH_BANNER.TEXT_4_DESC')}</label>
                </div>
              </div>
            </Fade>,
            <Fade right delay={800}>
              <div className="box">
                <img src={fourthBannerDT} alt={t('HOME.FOURTH_BANNER.TEXT_5_TITLE')} />
                <div>
                  <p className="f-primary bold">{t('HOME.FOURTH_BANNER.TEXT_5_TITLE')}</p>
                  <label className="f-tertiary text-desc">{t('HOME.FOURTH_BANNER.TEXT_5_DESC')}</label>
                </div>
              </div>
            </Fade>,
          ],
        ]}
        lowerItems={
          <div className="buttons">
            <Fade bottom>
              <Button className="btn-success btn-medium" type="button" onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.clarity.fullPath)}>
                <p className="f-primary">{t('HOME.FOURTH_BANNER.BUTTON_TEXT_1')}</p>
              </Button>
            </Fade>
          </div>
        }
        className={'usual-banner fourth-banner'}
      />
      <MainBanner
        upperItems={
          <div className="title-wrapper">
            <Crumbs matrix={blogBannerMatrixTop} canvasPosition={{ top: 0, left: 0 }} />
            <h1 className="f-secondary bold">{t('HOME.FIFTH_BANNER.TITLE')}</h1>
            <Fade>
              <p className="f-tertiary">{t('HOME.FIFTH_BANNER.DESC')}</p>
            </Fade>
          </div>
        }
        items={[
          [
            <BlogPost
              className={'col-sm-12 col-md-6 col-lg-4'}
              image={blogImage1}
              title={<h5 className="f-primary bold">{t('HOME.FIFTH_BANNER.BLOG_1_TITLE')}</h5>}
              desc={<p className="f-tertiary">{t('HOME.FIFTH_BANNER.BLOG_1_DESC')}</p>}
              button={
                <Button className="btn-transparent dark btn-large" onClick={() => window.open(blogLinks[0])}>
                  <p className="f-primary">{t('HOME.FIFTH_BANNER.BUTTON_TEXT_1')}</p>
                </Button>
              }
              WrapperComponent={({ children }) => <Fade>{children}</Fade>}
            />,

            <BlogPost
              className={'col-sm-12 col-md-6 col-lg-4'}
              image={blogImage2}
              title={<h5 className="f-primary bold">{t('HOME.FIFTH_BANNER.BLOG_2_TITLE')}</h5>}
              desc={<p className="f-tertiary">{t('HOME.FIFTH_BANNER.BLOG_2_DESC')}</p>}
              button={
                <Button className="btn-transparent dark btn-large" onClick={() => window.open(blogLinks[1])}>
                  <p className="f-primary">{t('HOME.FIFTH_BANNER.BUTTON_TEXT_1')}</p>
                </Button>
              }
              WrapperComponent={({ children }) => <Fade>{children}</Fade>}
            />,

            <BlogPost
              className={'col-sm-12 col-md-6 col-lg-4'}
              image={blogImage3}
              title={<h5 className="f-primary bold">{t('HOME.FIFTH_BANNER.BLOG_3_TITLE')}</h5>}
              desc={<p className="f-tertiary">{t('HOME.FIFTH_BANNER.BLOG_3_DESC')}</p>}
              button={
                <Button className="btn-transparent dark btn-large" onClick={() => window.open(blogLinks[2])}>
                  <p className="f-primary">{t('HOME.FIFTH_BANNER.BUTTON_TEXT_1')}</p>
                </Button>
              }
              WrapperComponent={({ children }) => <Fade>{children}</Fade>}
            />,
          ],
        ]}
        lowerItems={
          <>
            <div className="buttons">
              <Fade bottom>
                <Button className="btn-transparent btn-medium" type="button" onClick={() => window.open(HYBIRD_MEDIUM_URL)}>
                  <p className="f-secondary">{t('HOME.FIFTH_BANNER.BUTTON_TEXT_1')}</p>
                </Button>
              </Fade>
            </div>
            <Crumbs matrix={blogBannerMatrixBottom} canvasPosition={{ bottom: 0, right: '30%' }} />
          </>
        }
        className={'usual-banner fifth-banner'}
      />
    </div>
  );
};

Home.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Home;
