import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MainBanner from '../../shared/main-banner/components/main-banner';
import Stepper from '../../shared/stepper/components/stepper';
import Crumbs from '../../shared/crumbs/components/crumbs';

import Button from '../../../common/form/components/button';

import { threeDrmMatrix, stepperMatrix } from '../constants/three-drm';

import { ReactComponent as Stroke } from '../assets/vector-stroke.svg';
import { ReactComponent as TimelinePin } from '../assets/timeline-pin.svg';
import cameras from '../assets/cameras.png';

import growth from '../assets/growth.png';
import media from '../assets/media.png';
import education from '../assets/education.png';
import technology from '../assets/technology.png';
import area from '../assets/area.png';
import factory from '../assets/factory.png';
import tanks from '../assets/tanks.png';

import image1 from '../assets/image-1.png';
import image2 from '../assets/image-2.png';
import image3 from '../assets/image-3.png';

import { THEMES } from '../../../common/constants';

import playBtnImage from '../assets/play-btn.png';

import soundAudio from '../assets/audio.mp3';

import '../styles/three-drm.scss';

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(
    () => {
      playing ? audio.play() : audio.pause();
    },
    [audio, playing]
  );

  useEffect(
    () => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    },
    [audio]
  );

  return [playing, toggle];
};

const threeDRM = (props, { t }) => {
  //eslint-disable-next-line
  const [playing, toggle] = useAudio(soundAudio);

  return (
    <div className="three-drm-container">
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [<h1 className="f-secondary bold">{t('THREE_DRM.FIRST_BANNER.TITLE')}</h1>, <Crumbs matrix={threeDrmMatrix} canvasPosition={{ bottom: -122, right: -132 }} />],
          [
            <div className="image">
              <img src={cameras} alt="" />
              <Stroke />
            </div>,
          ],
        ]}
        className="usual-banner first-banner"
      />
      <MainBanner
        items={[
          [
            <div className="col-md-12 col-sm-12">
              <h1 className="f-primary title bold">{t('THREE_DRM.SECOND_BANNER.TITLE')}</h1>

              <h1 className="f-primary bold main-text">{t('THREE_DRM.SECOND_BANNER.MAIN_TEXT')}</h1>
              <p className="f-tertiary bold">{t('THREE_DRM.SECOND_BANNER.TEXT_1')}</p>
              <p className="f-tertiary">{t('THREE_DRM.SECOND_BANNER.TEXT_2')} </p>
              <p className="f-tertiary">{t('THREE_DRM.SECOND_BANNER.TEXT_3')}</p>
            </div>,
            <Button onClick={toggle} className="btn-transparent dark btn-small">
              <img src={playBtnImage} alt="" />
              <p className="f-primary bold noselect">{t('THREE_DRM.SECOND_BANNER.BTN_TEXT')}</p>
            </Button>,
          ],
        ]}
        className="usual-banner second-banner"
      />
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [<p className="f-tertiary">{t('THREE_DRM.THIRD_BANNER.TITLE_1')}</p>, <p className="f-tertiary">{t('THREE_DRM.THIRD_BANNER.TEXT_1')}</p>],

          [<p className="f-tertiary">{t('THREE_DRM.THIRD_BANNER.TITLE_2')}</p>, <p className="f-tertiary">{t('THREE_DRM.THIRD_BANNER.TEXT_2')}</p>],
        ]}
        className="usual-banner third-banner"
      />
      <MainBanner
        upperItems={<h1 className="f-primary bold">{t('THREE_DRM.FOURTH_BANNER.TITLE_1')}</h1>}
        items={[
          [
            <p className="f-primary mt">{t('THREE_DRM.FOURTH_BANNER.TEXT_1')}</p>,
            <div className="icons">
              <div className="single-item">
                <img src={tanks} alt="" />

                <p className="f-tertiary" dangerouslySetInnerHTML={{ __html: t('THREE_DRM.FOURTH_BANNER.TEXT_2') }} />
              </div>
              <div className="single-item">
                <img src={area} alt="" />

                <p className="f-tertiary" dangerouslySetInnerHTML={{ __html: t('THREE_DRM.FOURTH_BANNER.TEXT_3') }} />
              </div>
              <div className="single-item">
                <img src={factory} alt="" />

                <p className="f-tertiary" dangerouslySetInnerHTML={{ __html: t('THREE_DRM.FOURTH_BANNER.TEXT_4') }} />
              </div>
            </div>,
          ],
        ]}
        className="usual-banner fourth-banner"
      />
      <MainBanner
        items={[
          [
            <Stepper
              stepperItems={[
                {
                  width: '10%',
                  color: THEMES.standard.stepperColorLight,
                  label: '',
                  backgroundImage: `linear-gradient(to right, ${THEMES.standard.primaryThemeColor} , ${THEMES.standard.stepperColorLight})`,
                },
                {
                  width: '10%',
                  color: THEMES.standard.stepperColorLight,
                  desc: t('THREE_DRM.FOURTH_BANNER.STEPPER_ITEM_1.DESC_1'),
                  subDesc: t('THREE_DRM.FOURTH_BANNER.STEPPER_ITEM_1.DESC_2'),
                  components: [
                    <div className="pin-wrapper-1">
                       <TimelinePin className="timeline-pin-1" />
                      <p className="f-primary">{t('THREE_DRM.FOURTH_BANNER.PIN_1_TEXT')}</p>
                    </div>,
                    <h1 className="f-secondary number">1</h1>,
                  ],
                },
                {
                  width: '15%',
                  color: THEMES.standard.stepperColorMedium,
                  desc: t('THREE_DRM.FOURTH_BANNER.STEPPER_ITEM_2.DESC_1'),
                  subDesc: t('THREE_DRM.FOURTH_BANNER.STEPPER_ITEM_2.DESC_2'),
                  components: [<Crumbs matrix={stepperMatrix(THEMES.standard.stepperColorLight)} canvasPosition={{ left: 0, top: 0 }} />, <h1 className="f-secondary number">2</h1>],
                },
                {
                  width: '37%',
                  color: THEMES.standard.stepperColorSemiDark,
                  desc: t('THREE_DRM.FOURTH_BANNER.STEPPER_ITEM_3.DESC_1'),
                  subDesc: t('THREE_DRM.FOURTH_BANNER.STEPPER_ITEM_3.DESC_2'),
                  components: [<Crumbs matrix={stepperMatrix(THEMES.standard.stepperColorMedium)} canvasPosition={{ left: 0, top: 0 }} />, <h1 className="f-secondary number">3</h1>],
                },
                {
                  width: '18%',
                  color: THEMES.standard.stepperColorDark,
                  desc: t('THREE_DRM.FOURTH_BANNER.STEPPER_ITEM_4.DESC_1'),
                  subDesc: t('THREE_DRM.FOURTH_BANNER.STEPPER_ITEM_4.DESC_2'),
                  components: [
                    <div className="pin-wrapper-2">
                       <TimelinePin className="timeline-pin-2" />
                      <p className="f-primary">{t('THREE_DRM.FOURTH_BANNER.PIN_2_TEXT')}</p>
                    </div>,
                    <Crumbs matrix={stepperMatrix(THEMES.standard.stepperColorSemiDark)} canvasPosition={{ left: 0, top: 0 }} />,
                    <h1 className="f-secondary number">4</h1>,
                  ],
                },
                {
                  width: '10%',
                  color: THEMES.standard.stepperColorDark,
                  label: '',
                  backgroundImage: `linear-gradient(to left, ${THEMES.standard.primaryThemeColor}, ${THEMES.standard.stepperColorDark})`,
                },
              ]}
            />,
          ],
        ]}
        className="usual-banner fifth-banner"
      />
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        upperItems={<h1 className="f-primary bold">{t('THREE_DRM.SIXTH_BANNER.TITLE')}</h1>}
        items={[
          [
            <img src={growth} alt="" />,
            <h5 className="f-primary bold">{t('THREE_DRM.SIXTH_BANNER.TEXT_1')} </h5>,
            <p className="f-tertiary">{t('THREE_DRM.SIXTH_BANNER.TEXT_2')} </p>,
            <label className="f-primary bold">{t('THREE_DRM.SIXTH_BANNER.TEXT_3')} </label>,
          ],
          [
            <img src={media} alt="" />,
            <h5 className="f-primary bold">{t('THREE_DRM.SIXTH_BANNER.TEXT_4')} </h5>,
            <p className="f-tertiary">{t('THREE_DRM.SIXTH_BANNER.TEXT_5')} </p>,
            <label className="f-primary bold">{t('THREE_DRM.SIXTH_BANNER.TEXT_6')} </label>,
          ],
        ]}
        className="usual-banner sixth-banner"
      />
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <img src={technology} alt="" />,
            <h5 className="f-primary bold">{t('THREE_DRM.EIGHT_BANNER.TEXT_1')} </h5>,
            <p className="f-tertiary">{t('THREE_DRM.EIGHT_BANNER.TEXT_2')} </p>,
            <label className="f-primary bold">{t('THREE_DRM.EIGHT_BANNER.TEXT_3')} </label>,
          ],
          [
            <img src={education} alt="" />,
            <h5 className="f-primary bold">{t('THREE_DRM.EIGHT_BANNER.TEXT_4')} </h5>,
            <p className="f-tertiary">{t('THREE_DRM.EIGHT_BANNER.TEXT_5')} </p>,
            <label className="f-primary bold">{t('THREE_DRM.EIGHT_BANNER.TEXT_6')} </label>,
          ],
        ]}
        className="usual-banner eight-banner"
      />
      <MainBanner
        upperItems={<h1 className="f-primary bold title">{t('THREE_DRM.SEVENTH_BANNER.TITLE')}</h1>}
        items={[
          [
            <div className="single-item">
              <div className="image">
                <img src={image1} alt="" />
              </div>
              <h5 className="f-primary bold">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_1.TEXT_1')} </h5>
              <h5 className="f-primary bold">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_1.TEXT_2')} </h5>
              <p className="f-tertiary">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_1.TEXT_3')} </p>
            </div>,
            <div className="single-metric">
              <h2 className="f-secondary">{t('THREE_DRM.SEVENTH_BANNER.METRIC_1.TEXT_1')}</h2>
              <p className="f-tertiary">{t('THREE_DRM.SEVENTH_BANNER.METRIC_1.TEXT_2')} </p>
            </div>,
          ],
          [
            <div className="single-item">
              <div className="image">
                <img src={image2} alt="" />
              </div>
              <h5 className="f-primary bold">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_2.TEXT_1')} </h5>
              <h5 className="f-primary bold">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_2.TEXT_2')} </h5>
              <p className="f-tertiary">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_2.TEXT_3')} </p>
            </div>,
            <div className="single-metric">
              <h2 className="f-secondary">{t('THREE_DRM.SEVENTH_BANNER.METRIC_2.TEXT_1')}</h2>
              <p className="f-tertiary">{t('THREE_DRM.SEVENTH_BANNER.METRIC_2.TEXT_2')} </p>
            </div>,
          ],
          [
            <div className="single-item">
              <div className="image">
                <img src={image3} alt="" />
              </div>
              <h5 className="f-primary bold">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_3.TEXT_1')} </h5>
              <h5 className="f-primary bold">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_3.TEXT_2')} </h5>
              <p className="f-tertiary">{t('THREE_DRM.SEVENTH_BANNER.IMAGE_3.TEXT_3')} </p>
            </div>,
            <div className="single-metric">
              <h2 className="f-secondary">{t('THREE_DRM.SEVENTH_BANNER.METRIC_3.TEXT_1')}</h2>
              <p className="f-tertiary">{t('THREE_DRM.SEVENTH_BANNER.METRIC_3.TEXT_2')} </p>
            </div>,
          ],
        ]}
        className="usual-banner seventh-banner"
      />
    </div>
  );
};

threeDRM.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default threeDRM;
