import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MainBanner from '../../shared/main-banner/components/main-banner';
import Button from '../../../common/form/components/button';

import firstBannerImage from '../assets/MacBook-Mockup.png';

import { ReactComponent as HoveredItem1 } from '../assets/first_layer.svg';
import { ReactComponent as HoveredItem2 } from '../assets/second_layer.svg';
import { ReactComponent as HoveredItem3 } from '../assets/third_layer.svg';

import thirdBannerImage1 from '../assets/WI.png';
import thirdBannerImage2 from '../assets/AI.png';
import thirdBannerImage3 from '../assets/3DRM.png';

import fourthBannerImage1 from '../assets/image_287.png';
import fourthBannerImage2 from '../assets/image_288.png';
import fourthBannerImage3 from '../assets/image_289.png';
import fourthBannerImage4 from '../assets/image_290.png';
import fourthBannerImage5 from '../assets/image_291.png';
import fourthBannerIcon from '../assets/fourth-banner-icon.png';
import fourthBannerIcon2 from '../assets/layer-2.png';
import fourthBannerIcon3 from '../assets/layer-3.png';
import fourthBannerIcon4 from '../assets/layer-4.png';
import fourthBannerIcon5 from '../assets/layer-5.png';

import singleLayer1 from '../assets/first_single_layer.png';
import singleLayer2 from '../assets/second_single_layer.png';
import singleLayer3 from '../assets/third_single_layer.png';

import { hoveredItems } from '../constants/clarity-constants';

import Helpers from '../../../core/helpers';
import routesConstants from '../../../common/routes-constants';

import Crumbs from '../../shared/crumbs/components/crumbs';
import { secondBannerTopMatrix, findOutMoreTopMatrix, findOutMoreBottomMatrix } from '../constants/clarity-constants';

import { CONTACT_US_TYPES } from '../../../common/constants';

import '../styles/clarity.scss';

const Clarity = (props, { t }) => {
  const [activeItem, setActive] = useState(null);

  const getSelectedClass = currentItem => {
    if (activeItem === null) return '';
    return Number.isInteger(activeItem) && activeItem !== currentItem ? 'not-selected' : 'selected';
  };

  return (
    <div className="clarity-container">
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <p className="f-tertiary">{t('CLARITY.FIRST_BANNER.TEXT_1')}</p>,
            <h1 className="f-secondary bold">{t('CLARITY.FIRST_BANNER.TEXT_2')}</h1>,
            <p className="f-tertiary">{t('CLARITY.FIRST_BANNER.TEXT_3')}</p>,
            <div className="buttons">
              <Button onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.contactUs.fullPath, [{ rt: CONTACT_US_TYPES.request.type }])} className="btn-success btn-small">
                <p className="f-secondary">{t('CLARITY.FIRST_BANNER.BUTTON_TEXT_1')}</p>
              </Button>
            </div>,
          ],
          [<img src={firstBannerImage} alt="" />],
        ]}
        className={'usual-banner first-banner'}
      />
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <iframe
              width="400"
              src="https://www.youtube.com/embed/dJg8M_Am_qQ?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              title="Clarity intro"
              allowFullScreen
            />,
          ],
          [
            <Crumbs matrix={secondBannerTopMatrix} canvasPosition={{ top: -50, right: '35%' }} />,
            <p className="f-tertiary">{t('CLARITY.SECOND_BANNER.TEXT_1')}</p>,
            <h1 className="f-primary bold">{t('CLARITY.SECOND_BANNER.TEXT_2')}</h1>,
            <p className="f-tertiary">{t('CLARITY.SECOND_BANNER.TEXT_3')}</p>,
          ],
        ]}
        className={'usual-banner second-banner'}
      />
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <div className="hovered-items invisible-sm">
              <HoveredItem1 onMouseEnter={() => setActive(hoveredItems.first)} onMouseLeave={() => setActive(null)} className={`${getSelectedClass(hoveredItems.first)}`} />
              <HoveredItem2 onMouseEnter={() => setActive(hoveredItems.second)} onMouseLeave={() => setActive(null)} className={`${getSelectedClass(hoveredItems.second)}`} />
              <HoveredItem3 onMouseEnter={() => setActive(hoveredItems.third)} onMouseLeave={() => setActive(null)} className={`${getSelectedClass(hoveredItems.third)} `} />
            </div>,
          ],
          [
            <h1 className="f-primary bold">{t('CLARITY.THIRD_BANNER.TEXT_1')}</h1>,
            <div className={`list ${getSelectedClass(hoveredItems.first)}`} onMouseEnter={() => setActive(hoveredItems.first)} onMouseLeave={() => setActive(null)}>
              <img src={thirdBannerImage1} alt={t('CLARITY.THIRD_BANNER.LIST_1_TITLE')} className="invisible-sm" />
              <img src={singleLayer1} alt={t('CLARITY.THIRD_BANNER.LIST_1_TITLE')} className="visible-sm" />
              <div>
                <p className="f-primary bold">{t('CLARITY.THIRD_BANNER.LIST_1_TITLE')}</p>
                <ul>
                  <li className="f-tertiary">{t('CLARITY.THIRD_BANNER.LIST_1_ITEM_1')}</li>
                  <li className="f-tertiary">{t('CLARITY.THIRD_BANNER.LIST_1_ITEM_2')}</li>
                </ul>
              </div>
            </div>,
            <div className={`list ${getSelectedClass(hoveredItems.second)}`} onMouseEnter={() => setActive(hoveredItems.second)} onMouseLeave={() => setActive(null)}>
              <img src={thirdBannerImage2} alt={t('CLARITY.THIRD_BANNER.LIST_2_TITLE')} className="invisible-sm" />
              <img src={singleLayer2} alt={t('CLARITY.THIRD_BANNER.LIST_1_TITLE')} className="visible-sm" />

              <div>
                <p className="f-primary bold">{t('CLARITY.THIRD_BANNER.LIST_2_TITLE')}</p>
                <ul>
                  <li className="f-tertiary">{t('CLARITY.THIRD_BANNER.LIST_2_ITEM_1')}</li>
                  <li className="f-tertiary">{t('CLARITY.THIRD_BANNER.LIST_2_ITEM_2')}</li>
                </ul>
              </div>
            </div>,
            <div className={`list ${getSelectedClass(hoveredItems.third)}`} onMouseEnter={() => setActive(hoveredItems.third)} onMouseLeave={() => setActive(null)}>
              <img src={thirdBannerImage3} alt={t('CLARITY.THIRD_BANNER.LIST_3_TITLE')} className="invisible-sm" />
              <img src={singleLayer3} alt={t('CLARITY.THIRD_BANNER.LIST_1_TITLE')} className="visible-sm" />

              <div>
                <p className="f-primary bold">{t('CLARITY.THIRD_BANNER.LIST_3_TITLE')}</p>
                <ul>
                  <li className="f-tertiary">{t('CLARITY.THIRD_BANNER.LIST_3_ITEM_1')}</li>
                  <li className="f-tertiary">{t('CLARITY.THIRD_BANNER.LIST_3_ITEM_2')}</li>
                </ul>
              </div>
            </div>,
          ],
        ]}
        className={'usual-banner third-banner'}
      />

      {/* last five banners */}
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [<div className="image-holder" style={{ backgroundImage: `url("${fourthBannerImage1}")` }} />],
          [
            <img src={fourthBannerIcon} alt="" className="invisible-sm" />,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_1_TEXT_1')}</p>,
            <h1 className="f-primary bold">{t('CLARITY.FOURTH_BANNER.ITEM_1_TEXT_2')}</h1>,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_1_TEXT_3')}</p>,
          ],
        ]}
        lowerItems={<div className="spacer" />}
        className={'usual-banner fourth-banner no-pb'}
      />
      <MainBanner
        items={[
          [
            <div className="image-holder" style={{ backgroundImage: `url("${fourthBannerImage2}")` }}>
              {/* <img className="main-image" src={fourthBannerImage1} alt="" /> */}
            </div>,
          ],
          [
            <img src={fourthBannerIcon2} alt="" className="invisible-sm" />,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_2_TEXT_1')}</p>,
            <h1 className="f-primary bold">{t('CLARITY.FOURTH_BANNER.ITEM_2_TEXT_2')}</h1>,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_2_TEXT_3')}</p>,
          ],
        ]}
        lowerItems={<div className="spacer" />}
        className={'usual-banner fourth-banner no-pb no-pt'}
      />
      <MainBanner
        items={[
          [
            <div className="image-holder" style={{ backgroundImage: `url("${fourthBannerImage3}")` }}>
              {/* <img className="main-image" src={fourthBannerImage1} alt="" /> */}
            </div>,
          ],
          [
            <img src={fourthBannerIcon3} alt="" className="invisible-sm" />,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_3_TEXT_1')}</p>,
            <h1 className="f-primary bold">{t('CLARITY.FOURTH_BANNER.ITEM_3_TEXT_2')}</h1>,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_3_TEXT_3')}</p>,
          ],
        ]}
        lowerItems={<div className="spacer" />}
        className={'usual-banner fourth-banner no-pb no-pt'}
      />
      <MainBanner
        items={[
          [
            <div className="image-holder" style={{ backgroundImage: `url("${fourthBannerImage4}")` }}>
              {/* <img className="main-image" src={fourthBannerImage1} alt="" /> */}
            </div>,
          ],
          [
            <img src={fourthBannerIcon4} alt="" className="invisible-sm" />,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_4_TEXT_1')}</p>,
            <h1 className="f-primary bold">{t('CLARITY.FOURTH_BANNER.ITEM_4_TEXT_2')}</h1>,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_4_TEXT_3')}</p>,
          ],
        ]}
        lowerItems={<div className="spacer" />}
        className={'usual-banner fourth-banner no-pb no-pt'}
      />
      <MainBanner
        items={[
          [
            <div className="image-holder" style={{ backgroundImage: `url("${fourthBannerImage5}")` }}>
              {/* <img className="main-image" src={fourthBannerImage1} alt="" /> */}
            </div>,
          ],
          [
            <img src={fourthBannerIcon5} alt="" className="invisible-sm" />,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_5_TEXT_1')}</p>,
            <h1 className="f-primary bold">{t('CLARITY.FOURTH_BANNER.ITEM_5_TEXT_2')}</h1>,
            <p className="f-tertiary">{t('CLARITY.FOURTH_BANNER.ITEM_5_TEXT_3')}</p>,
          ],
        ]}
        lowerItems={<div className="spacer invisible-sm" />}
        className={'usual-banner fourth-banner no-pt'}
      />
      <MainBanner
        upperItems={
          <div className="title-wrapper">
            <Crumbs matrix={findOutMoreTopMatrix} canvasPosition={{ top: 0, left: 0 }} />
            <h1 className="f-secondary bold">{t('CLARITY.FIFTH_BANNER.TITLE')}</h1>
            <p className="f-secondary">{t('CLARITY.FIFTH_BANNER.DESC')}</p>
          </div>
        }
        items={[]}
        lowerItems={
          <div className="buttons">
            <Button onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.contactUs.fullPath)} className="btn-transparent btn-medium">
              <p className="f-secondary">{t('CLARITY.FIFTH_BANNER.BUTTON_TEXT_1')}</p>
            </Button>
            <Crumbs matrix={findOutMoreBottomMatrix} canvasPosition={{ bottom: 0, right: '30%' }} />
          </div>
        }
        className={'usual-banner fifth-banner'}
      />
    </div>
  );
};

Clarity.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Clarity;
