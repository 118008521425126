import { fields } from '../constants/white-paper-constants';

import { EMAIL_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[fields.email]) {
    errors[fields.email] = 'WHITE_PAPER_FORM.EMAIL_REQUIRED';
  }

  if (values[fields.email] && !EMAIL_REGEX.test(values[fields.email])) {
    errors[fields.email] = 'WHITE_PAPER_FORM.EMAIL_INVALID';
  }

  if (!values[fields.firstName]) {
    errors[fields.firstName] = 'WHITE_PAPER_FORM.FIRST_NAME_REQUIRED';
  }

  if (!values[fields.lastName]) {
    errors[fields.lastName] = 'WHITE_PAPER_FORM.LAST_NAME_REQUIRED';
  }
  if (!values[fields.companyName]) {
    errors[fields.companyName] = 'WHITE_PAPER_FORM.COMPANY_NAME_REQUIRED';
  }

  if (!values[fields.role]) {
    errors[fields.role] = 'WHITE_PAPER_FORM.ROLE_RQUIRED';
  }

  if (!values[fields.privacy]) {
    errors[fields.privacy] = 'WHITE_PAPER_FORM.PRIVACY_NOT_CHECKED';
  }

  return errors;
};
