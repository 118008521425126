import React from 'react';

import '../styles/text-area.scss';

const TextArea = props => {
  const { input, placeholder, meta, id, label, labelClass, disabled, className, t, rows, cols } = props;

  return (
    <div className={`text-area-input ${className}`}>
      {label && (
        <label className={`input-label ${labelClass || ''}`} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={`input-wrapper ${meta && meta.error && meta.touched ? 'error' : ''}`}>
        <textarea id={id} {...input} readOnly={disabled} disabled={disabled} rows={rows} cols={cols} placeholder={placeholder} />
      </div>
      <div className={`error-message ${meta && meta.error && meta.touched ? 'visible' : ''}`}>{meta && meta.error && meta.touched && (t ? t(meta.error) : meta.error)}</div>
    </div>
  );
};
TextArea.defaultProps = {
  disabled: false,
  className: '',
  placeholder: '',
};

export default TextArea;
