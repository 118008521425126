import React from 'react';

import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY } from '../../../../common/constants';

import { styles } from '../constants/map-style';

const Map = ({ markers, center, zoom }, { t }) => {
  const renderMarkers = (map, maps) => {
    if (maps)
      markers.forEach(markerLocation => {
        const { lat, lng, title } = markerLocation;
        new maps.Marker({
          position: { lat, lng },
          map,
          title,
        });
      });
  };

  return (
    <GoogleMapReact
      options={{ styles }}
      bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
      defaultCenter={center}
      defaultZoom={zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    />
  );
};

export default Map;
