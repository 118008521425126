import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';

const rootReducer = combineReducers({
  i18nState,
  form: formReducer,
});

export default rootReducer;
