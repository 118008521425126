import React, { Fragment } from 'react';

import '../styles/main-banner.scss';

const MainBanner = ({ items, upperItems, lowerItems, className = '', additonalStyle = {}, classDefinitions }) => {
  return (
    <div className={`main-banner ${className}`} style={{ additonalStyle }}>
      <div className="content-items">
        {upperItems && upperItems}
        <div className="items">
          {items.map((rows, index) => {
            return (
              <div className={`row num-${index} ${(classDefinitions && classDefinitions[index]) || ''}`} key={index}>
                {rows.map((item, i) => {
                  return <Fragment key={i}>{item}</Fragment>;
                })}
              </div>
            );
          })}
        </div>
        {lowerItems && lowerItems}
      </div>
    </div>
  );
};

export default MainBanner;
