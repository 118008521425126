import React from 'react';
import PropTypes from 'prop-types';

import MainBanner from '../../shared/main-banner/components/main-banner';

import { HYBIRD_DATA } from '../../../common/constants';

import '../styles/terms.scss';

const Privacy = (props, { t }) => {
  return (
    <div className="terms-container">
      <MainBanner
        items={[
          [
            <h1 className="f-primary title">HyBird Ltd. Terms and Conditions</h1>,
            <ol className="main-ol">
              <li>
                <p className="f-primary bold">Definitions and Interpretation</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">Unless the context requires otherwise, in these Terms and Conditions and in a Subscription:</p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Annual Services”:</em> means the provision by HyBird Ltd. of the Services specified in a Subscription for a fixed period of twelve (12) months.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“HyBird Ltd.”:</em> HyBird Ltd, a company registered in England with number 09863415 and its registered office at Unit 4, 45 Holmes Road, London, United
                      Kingdom, NW5 3AN.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“HyBird Ltd. Platform”:</em> means HyBird Ltd.’s proprietary web platform which can be accessed at clarity.hybirdtech.com or any hybirdtech.com domain
                      or sub-domain.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“HyBird Ltd. Pricing and Payments Policy”:</em> means the then-current version of HyBird Ltd.’s pricing and payments policy in respect of HyBird Ltd.
                      Services.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“HyBird Ltd. Services”:</em> means the workflow automation software tools with the functionality, features and other benefits, and subject to the
                      workflow and monthly actions limits, associated with a “Guest”, “Company”, or “Admin”.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Business Days”:</em> means Mondays to Fridays, excluding English Bank and public holidays.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Charges”:</em> means: in the case of Annual Services, the annual subscription charges or; in the case of Monthly Services, the monthly subscription
                      charges,
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Contract”:</em> means a contract between HyBird Ltd. and the Customer made up of a Subscription, these Terms and Conditions and any documents expressly
                      referred to in either of them, and any amendments to that contract from time to time.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Customer”:</em> means the person or entity identified as such in a Subscription, or free trial.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Customer Data”:</em> means any data provided by the Customer to HyBird Ltd. or created by the Customer under or in connection with a Contract. This
                      includes, but not limited to, images, videos, messages, reviews, maps, 3d models, 2d models, text, illustrations onto images and videos.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Customer Personal Data”:</em> means any Personal Data provided by the Customer to HyBird Ltd. under or in connection with a Contract and in respect of
                      which the Customer is a Data Controller.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">&quot;Confidential Information&quot;:</em> means any information disclosed or made available (whether in writing, verbally or otherwise) by either party
                      (“Discloser”) to the other party (“Recipient”) including: any information that is marked, or at the time of disclosure is otherwise designated, as being confidential any
                      information that would be regarded as confidential by a reasonable business person in or relating to the business, affairs operations, processes, products, inventions, know-how,
                      trade secrets, designs or software of the Discloser (including, in the case of HyBird Ltd.); HyBird Ltd. Terms &amp; Conditions February 2020 and any information or analysis
                      derived from any of the information referred to above.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Data Controller”:</em> has the meaning given to it in the Data Protection Legislation.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Data Processor”:</em> has the meaning given to it in the Data Protection Legislation.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Data Protection Legislation”:</em> means: the EU Data Protection Directive (Directive 95/46/EC), the Data Protection Act 1998 and Electronic
                      Communications (EC Directive) Regulations 2003; the EU General Data Protection Regulation (Regulation (EU) 2016/679); Data Protection Act 2018; and any other data protection laws
                      and regulations, orders and any codes of practice, guidelines and recommendations issued by the information commissioner’s office or any replacement or equivalent body, as
                      amended and in force from time to time.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Force Majeure Event”:</em> an event, or a series of related events, that is outside the reasonable control of the party affected, including: failures
                      of the internet or any public telecommunications network; hacker attacks; denial of service attacks; virus or other malicious software attacks or infections; power failures;
                      industrial disputes affecting any third party; changes to the law; disasters, explosions, fires, floods, riots, terrorist attacks and wars; failure of computer systems or
                      infrastructure owned or managed by a party’s subcontractor or supplier.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Intellectual Property Rights”:</em> patents, rights in inventions, know how, show how and trade secrets, copyright and related rights, moral rights,
                      registered designs, design rights, database rights, semiconductor topography rights, trade marks and service marks, trade names, business names, brand names, get up, logos,
                      domain names and URLs, rights in unfair competition, goodwill and rights to sue for passing off and any other intellectual property rights (in each case, whether or not
                      registered, and including all applications to register and rights to apply to register any of them and all rights to sue for any past or present infringement of them) and all
                      rights or forms of protection having equivalent or similar effect in any jurisdiction.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Monthly Services”:</em> means the provision by HyBird Ltd. of the Services specified in a Subscription on a monthly basis.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Personal Data”:</em> has the meaning given to it in the Data Protection Legislation.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Platform”:</em> refers to any software platform HyBird Ltd. offers on the cloud, or on premise.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Processing”:</em> has the meaning given to it in the Data Protection Legislation.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Services”:</em> means: the HyBird Ltd. Services; and the Platform Services, or, as required by the context, either of them.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Specifications”:</em> means the descriptions of the HyBird Ltd. Services on the HyBird Ltd. Platform.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Subscription”:</em> means an order for the supply of Services that is submitted by the Customer via the HyBird Ltd. Platform.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Support Services”:</em> means the support and maintenance services that are available in relation to a “Pro”, “Elite”, “Demo”, “Trial” or “Enterprise”
                      package.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">&quot;Term&quot;:</em> has the meaning given to it in clause 2.1.
                    </p>
                    <p className="f-tertiary mt-usual">
                      <em className="f-primary">“Workflows”:</em> has the meaning given to it in clause 10.3.
                    </p>
                  </li>
                </ol>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">The clause headings do not affect the interpretation of the Contract.</p>
                    <p className="f-tertiary mt-usual">
                      that statute or statutory provision as modified, consolidated and/or re-enacted from time to time; and any subordinate legislation made under that statute or statutory provision.
                    </p>
                  </li>
                </ol>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">In the Contract, a reference to a statute or statutory provision includes a reference to:</p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      In the Contract, general words shall not be given a restrictive interpretation by reason of being preceded or followed by words indicating a particular class of acts, matters or
                      things.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      In case of a conflict or inconsistency between these Terms and Conditions and any provision in a Subscription, the provision in the Subscription prevails.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Term</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      Subject to earlier termination in accordance with clause 14, the Contract will continue in force until cancelled by either party in accordance with the HyBird Ltd. Pricing and
                      Payments Policy, such cancellation to be effective:
                    </p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">in the case of Annual Services, at the end of the then-current 12-month subscription period; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">in the case of Monthly Services, at the end of the then-current one-month subscription period,</p>
                      </li>
                      <li>
                        <p className="f-tertiary">(the “Term”).</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">The parties agree that:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">the Annual Services will renew automatically for successive, 12-month subscription periods; and</p>
                      </li>
                      <li>
                        <p className="f-tertiary">the Monthly Services will renew automatically for successive, one-month subscription periods.</p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">HyBird Ltd. Services</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      Subject to the Customer complying with clauses 3.2 and 3.3, HyBird Ltd. grants the Customer a non-exclusive, non-transferable licence to access the HyBird Ltd. Platform during
                      the Term for the sole purpose of accessing and using the HyBird Ltd. Services for its business purposes.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">The Customer agrees that it will throughout the Term:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">use the HyBird Ltd. Services in a professional manner and only for the purposes for which they are made available;</p>
                      </li>
                      <li>
                        <p className="f-tertiary">use the HyBird Ltd. Services in accordance with all applicable laws; and</p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          choose a strong password and keep all login credentials secure and confidential, and otherwise ensure that no unauthorised person gains access to the HyBird Ltd. Services.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">The Customer agrees that it will not:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">
                          sub-license, rent, lease, loan, or sell its right to access the HyBird Ltd. Services to any third party, or use the HyBird Ltd. Services for the benefit of any third party;
                          or
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          not use the HyBird Ltd. Services in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or
                          activity; or
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">send spam or other unwelcomed communications to others; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">breach the intellectual property or other proprietary rights of HyBird Ltd. or any third party; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">upload to the HyBird Ltd. Platform anything that contains software viruses, worms, or any other harmful code; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the HyBird Ltd. Platform or any related technology, or any part thereof;
                          or
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">“scrape,” “crawl” or “spider” the HyBird Ltd. Platform, whether by manual means or using automated software, devices, scripts robots; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          interfere with the operation of, or place an unreasonable load on, the HyBird Ltd. Platform, including by way of spam, denial of service attacks or viruses, or otherwise use
                          the HyBird Ltd. Platform in any way that causes, or may cause, damage to it, provided that (for the avoidance of doubt) nothing in this clause shall prevent or restrict the
                          Customer from creating, using, copying, selling or otherwise exploiting Workflows.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      If the Customer breaches of any of the conditions or restrictions set out in clauses 3.2 or 3.3, then HyBird Ltd. may with immediate effect and without any obligation to provide
                      notice:
                    </p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">suspend the Customer’s access to the HyBird Ltd. Platform and use of the HyBird Ltd. Services; and/or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">terminate the Contract.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">HyBird has the right to terminate the contract at any time either for Annual or Monthly subscriptions, without prior notice to the Customer.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Support Services and Service Availability</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      HyBird Ltd. shall use all reasonable commercial endeavours to ensure that the availability of the HyBird Ltd. Services and Platforms during any calendar month during the Term
                      <em className="f-primary">(“Service Availability”)</em> exceeds 99.5%.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">For the purposes of calculating Service Availability under clause 4.2, any periods of non-availability of the HyBird Ltd. Services caused by:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">scheduled or planned maintenance carried out by HyBird Ltd.; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">non-availability of the internet, or a failure of the Customer’s equipment, or a power outage at the Customer’s premises; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          any act or omission of the Customer, or any person acting on behalf of the Customer, which prevents or delays the availability of the HyBird Ltd. Services; or
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">a Force Majeure Event.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      If the Customer has purchased any paid Package of Annual Services and Service Availability is less than 99.5% during three consecutive calendar months during the Term, then the
                      Customer may treat such non-availability as a material breach which is not of remedy for the purposes of clause 16.1.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Customer Data</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      Customers will add and upload data and information to the HyBird Platform; this may include: content, messages, images, videos, documents in any form, 3D models in any form,
                      data, text, maps, reviews; these are referred to as “Customer Data”.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">For HyBird to provide its Services, HyBird requires access to the Customer Data.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">The Customer hereby grants to HyBird Ltd. the following non-exclusive licences:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">
                          to collect, copy, store, distribute, export, edit and translate the Customer Data to the extent reasonably required for the performance of HyBird Ltd.&#39;s obligations and
                          the exercise of HyBird Ltd.&#39;s rights under these Terms and Conditions, together with the right to sub-license these rights to its hosting, connectivity and
                          telecommunications service providers to the extent reasonably required for the performance of HyBird Ltd.&#39;s obligations and the exercise of HyBird Ltd.&#39;s rights under
                          the Contract; and
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          to collect, store, aggregate, analyse and create copies of the Customer Data for the purposes of optimising and improving the Services, and creating new or derivative
                          products and services.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">The Customer warrants to HyBird Ltd. that the use of the Customer Data by HyBird Ltd. in accordance with the Contract will not:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">breach the provisions of any law, statute or regulation;</p>
                      </li>
                      <li>
                        <p className="f-tertiary">infringe the Intellectual Property Rights or other legal rights of any person; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">give rise to any cause of action against HyBird Ltd.,</p>
                      </li>
                      <li>
                        <p className="f-tertiary">in each case in any jurisdiction and under any applicable law.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      This license by the Customer to HyBird (as detailed in this section “5. Customer Data”) shall remain and continue even if the Customer stops using HyBird Services, and/or
                      Platform.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      The Customer shall retain Intellectual Property of the data; ownership is not transferred to HyBird; HyBird is granted a license (as detailed in this section 5.3).
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Trial/Demo Subscription</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">HyBird may offer a free demo/trial version of its platform. Users will have a fixed term period of a predefined number of days.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">The number of days may vary depending on where the trial originated.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">All the terms detailed in this document still apply.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      HyBird makes no commitment to any data or features as offered in demo/trial versions. Features available in the demo/trial version may not be available within the main
                      product/service. The trial version may contain code that is not fully tested.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">HyBird reserves the right, without any prior or further notice, to end a demo/trial license; to delete any data within a demo/trial subscription.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Refund</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">Any refund request following the purchase date and during the Subscription Period will be subject to HyBird’s prior authorization, and acceptance.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">Any refund shall be at HyBird&#39;s sole discretion.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Confidentiality</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">The Recipient shall:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">keep the Confidential Information of the Discloser strictly confidential;</p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          not disclose the Discloser’s Confidential Information to any person without the Discloser’s prior written consent, and then only under conditions of confidentiality no less
                          onerous than those contained in these Terms and Conditions; and
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          use the same degree of care to protect the confidentiality of the Discloser’s Confidential Information as the Recipient uses to protect its own confidential information of a
                          similar nature, being at least a reasonable degree of care.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      Notwithstanding clause 8.1, the Recipient may disclose the Discloser’s Confidential Information to its officers, employees, professional advisers, insurers, agents and
                      subcontractors who are bound by a written agreement or professional obligation to protect the confidentiality of such Confidential Information.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">This clause 8 imposes no obligations upon the Recipient with respect to the Discloser’s Confidential Information that:</p>

                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">
                          is known to the Recipient before disclosure under or in connection with the Contract, and is not subject to any other obligation of confidentiality;
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">is or becomes publicly known through no act or default of the Recipient; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          is obtained by the Recipient from a third party in circumstances where the Recipient has no reason to believe that there has been a breach of an obligation of
                          confidentiality.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      The restrictions in this clause 8 do not apply to the extent that any Confidential Information is required to be disclosed by any law or regulation, by any judicial or HyBird
                      Ltd. Terms &amp; Conditions February 2020 governmental order or request, or pursuant to disclosure requirements relating to the listing of the stock of the Recipient on any
                      recognised stock exchange.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      The provisions of this clause 8 shall continue in force for a period of five (5) years following the expiry or earlier termination of the Contract, at the end of which period
                      they will cease to have effect.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Data Protection</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      The parties agree that, in respect of any Customer Personal Data that is provided by the Customer to HyBird Ltd. then, for the purposes of the Data Protection Legislation, the
                      Customer is the Data Controller and HyBird Ltd. is the Data Processor of such Customer Personal Data.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">HyBird Ltd. will, at all times:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">
                          process the Customer Personal Data only to the extent, and in such a manner, as is necessary for the purposes of the Contract and in accordance with the Customer’s lawful
                          written instructions from time to time;
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          ensure the reliability of all its personnel who have access to Customer Personal Data and, in particular, ensure that any person authorised to process Customer Personal Data
                          in connection with the Contract is subject to a duty of confidentiality;
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          provide the Customer, at the Customer’s own expense, with reasonable co- operation and assistance in relation to the Customer’s obligations and rights under Data Protection
                          Legislation;
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          implement and maintain technical and organisational measures against the unauthorised or unlawful processing to, and the accidental loss or destruction of, or damage to,
                          Customer Personal Data;
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">promptly notify the Customer, and provide such co-operation, assistance and information as the Customer may reasonably require, if HyBird Ltd.:</p>
                        <ol className="sub-3 roman">
                          <li>
                            <p className="f-tertiary">
                              receives any complaint, notice or communication which relates to the processing of the Customer Personal Data under the Contract or to either party’s compliance with Data
                              Protection Legislation; or
                            </p>
                          </li>
                          <li>
                            <p className="f-tertiary">becomes aware of any personal data breach unless it is unlikely to result in a risk to a data subject;</p>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          keep a written record of all processing of Customer Personal Data carried out in the course of the HyBird Ltd. Services and provide the Customer or its third- party
                          representatives a copy of HyBird Ltd.’s most recent audit results to demonstrate HyBird Ltd.’s compliance with its obligations as a processor under the Contract; and
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          destroy or, if required by the Customer and at the Customer’s cost, return all Customer Personal Data that it processes acting on behalf of the Customer and has in its
                          possession and delete existing copies of such data unless applicable law requires storage of the Customer Personal Data.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">The Customer warrants that it will comply with its obligations under the Data Protection Legislation.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Intellectual Property Rights</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      Nothing in the Contract shall operate to assign or otherwise transfer any Intellectual Property Rights from HyBird Ltd. to the Customer, whether created prior to or during the
                      Term.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      All Intellectual Property Rights in HyBird Ltd. Platforms, and Services, together with any improvements, enhancements or other modifications to either of them, whether or not
                      created jointly with the Customer, (“Improvements”) shall vest and remain vested in HyBird Ltd..
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      Subject to clause 10.2, all Intellectual Property Rights in in all workflows and other materials created by Customer using the HyBird Ltd. Services (“Workflows”) shall vest or
                      remain vested in the Customer.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Charges</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">The Customer agrees that HyBird Ltd. may use the payment method selected by the Customer to process payment of the Charges:</p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      The Charges are set in Great British Pounds (GBP), exclusive of UK value added tax (VAT) or any other applicable sales tax, which will be added to the Charges and paid in
                      accordance with clause 11.1.
                    </p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">in the case of Annual Services, annually in advance; and</p>
                      </li>
                      <li>
                        <p className="f-tertiary">in the case of Monthly Services, monthly in advance.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      HyBird Ltd. may increase the Charges by giving to the Customer at least thirty (30) days&#39; prior written notice, provided that, in the case of Annual Services, any increases
                      to the Charges shall only be effective following expiry of the then-current 12-month subscription period.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">The payment methods accepted are those mentioned on the HyBird website, and or in a quotation submitted by HyBird.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      Payments made are completed using secured payment service providers. For payments completed via HyBird websites (or subdomains) are completed using Stripe. No financial payment
                      information is processed by HyBird.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      You fully discharge HyBird from any and all liability in the respect of the malfunction of payment method; all liability is accepted by the Customer. HyBird shall not be held
                      accountable for any payment failure.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      By subscribing to HyBird Products/Services/Subscriptions that require requiring payments on a monthly or annual basis, the Customer authorises HyBird to charge the payment method
                      automatically. The Customer warrants that they shall remain the owner of the payment method specified for the recurring payments. The Customer shall ensure the payment method has
                      sufficient limits / funds to pay any due payments and/or recurring payments.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      To avoid any doubt, one-month represents a period of thirty (30) consecutive calendar days starting from the day of completing the payment method; up until 23:59 of the day.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      If the Customer cancels his/her subscription, the following recurring payment, for the following month, or the following year, will not be taken. Cancellation should take place
                      prior to 23:59 of the last day of the subscription month.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      If the recurring payment method fails, HyBird will send a written notice to the Customer (by email) and shall retry the payment after 2 (two) calendar days.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      The Customer acknowledges and agrees that if HyBird Ltd. is unable to collect payment of the Charges using the payment method selected by the Customer, HyBird Ltd. may, without
                      prejudice to its other rights (whether under the Contract or otherwise), suspend the provision of the HyBird Ltd. Services or Platforms with immediate effect and without any
                      obligation to give prior notice of such suspension to the Customer or to refund the Customer for any Charges relating to the period of suspension.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Warranties</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">Each party warrants to other party that:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">it has the legal right and authority to enter into the Contract and to perform its obligations under the Contract; and</p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          it will comply with all applicable legal and regulatory requirements applying to the exercise of its rights and the fulfilment of its obligations under the Contract.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">HyBird Ltd. warrants to the Customer that:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">the Services and Platform are provided “as is”;</p>
                      </li>
                      <li>
                        <p className="f-tertiary">the HyBird Ltd. Services shall in all material respects comply with the Specification so far as is commercially reasonably; Contract.</p>
                      </li>
                      <li>
                        <p className="f-tertiary">the HyBird Ltd. Services will incorporate security features reflecting the requirements of good industry practice.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      All of the parties&#39; warranties and representations in respect of the subject matter of the Contract are expressly set out in the Contract. To the maximum extent permitted by
                      applicable law, no other warranties or representations concerning the subject matter of the Contract will be implied into the Contract or any related contract.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Acknowledgements and warranty limitations</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      The Customer acknowledges that, despite all reasonable commercial endeavours, complex software is never wholly free from defects, errors and bugs; and subject to the other
                      provisions of the Contract, HyBird Ltd. gives no warranty or representation that the HyBird Ltd. Services will be wholly free from defects, errors and bugs.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      The Customer acknowledges that, despite all reasonable commercial endeavours, complex software is never entirely free from security vulnerabilities; and subject to the other
                      provisions of the Contract, HyBird Ltd. gives no warranty or representation that the HyBird Ltd. Services will be entirely secure.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      Except to the extent expressly provided otherwise in the Contract, HyBird Ltd. does not warrant or represent that the HyBird Ltd. Services or the use of the HyBird Ltd. Services
                      by the Customer will be suitable for the Customer’s purposes, or will provide the Customer with any return on investment, cost saving or other specific result or outcome.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Limitations and exclusions of liability</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">Nothing in the Contract will limit or exclude:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">any liability for fraud or fraudulent misrepresentation; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">any liability in any way that is not permitted under applicable law.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      The limitations and exclusions of liability set out in this clause 14 and elsewhere in the Contract: provisions of the Contract, HyBird Ltd. gives no warranty or representation
                      that the HyBird Ltd. Services will be entirely secure.
                    </p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">are subject to clause 14.1; and</p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          govern all liabilities arising under the Contract or relating to the subject matter of the Contract, including liabilities arising in contract, in tort (including negligence)
                          and for breach of statutory duty, except to the extent expressly provided otherwise in the Contract.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">HyBird Ltd. shall not be liable to the Customer in respect of:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">any losses arising out of a Force Majeure Event; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">any loss of profits or anticipated savings; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">any loss of revenue or income; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">any loss of use or production; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">any loss of business, contracts or opportunities; or</p>
                      </li>
                      <li>
                        <p className="f-tertiary">any loss or corruption of any data, database or software,</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">Neither party shall be liable to the other party in respect of any special, indirect or consequential loss or damage.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Force Majeure Event</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      If a Force Majeure Event gives rise to a failure or delay in either party performing any obligation under the Contract (other than any obligation to make a payment), that
                      obligation will be suspended for the duration of the Force Majeure Event.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      A party that becomes aware of a Force Majeure Event which gives rise to, or which is likely to give rise to, any failure or delay in that party performing any obligation under
                      the Contract, must:
                    </p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">promptly notify the other; and</p>
                      </li>
                      <li>
                        <p className="f-tertiary">inform the other of the period for which it is estimated that such failure or delay will continue.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      A party whose performance of its obligations under the Contract is affected by a Force Majeure Event must take reasonable steps to mitigate the effects of the Force Majeure
                      Event.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Termination</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      Either party may terminate the Contract immediately by giving written notice of termination to the other party if the other party commits any material breach of the Contract
                      which, in the case of a breach which is capable of remedy, is not remedied within thirty (30) days of the date on which the other party is given written notice requiring the
                      breach to be remedied.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">Either party may terminate the Contract immediately by giving written notice of termination to the other party if:</p>
                    <ol className="sub-2 a">
                      <li>
                        <p className="f-tertiary">
                          the other party is dissolved, ceases to conduct all (or substantially all) of its business, is or becomes unable to pay its debts as they fall due, is or becomes insolvent or
                          is declared insolvent, or convenes a meeting or makes or proposes to make any arrangement or composition with its creditors; or
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          an administrator, administrative receiver, liquidator, receiver, trustee, manager or similar is appointed over any material part of the assets of the other party; or
                        </p>
                      </li>
                      <li>
                        <p className="f-tertiary">
                          an order is made for the winding up of the other party, or the other party passes a resolution for its winding up (other than for the purpose of a solvent company
                          reorganisation where the resulting entity will assume all the obligations of the other party under the Contract).
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="f-tertiary">HyBird Ltd. may terminate the Contract at any time at HyBird Ltd.’s sole discretion.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Effects of termination</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      Upon the termination of the Contract, all of the provisions of the Contract shall cease to have effect, save that the following provisions of the Contract shall survive and
                      continue to have effect (in accordance with their express terms or otherwise indefinitely): clauses 1, 5, 7, 8, 9, 10, 11, 14, 17, 23, 24, 25, 27 and 28.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">The termination of the Contract shall not affect the accrued rights of either party.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      On termination of the Contract for any reason the Customer must immediately pay to HyBird Ltd. any Charges in respect of Services provided to the Customer before the termination
                      of the Contract.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      The Customer agrees that it is the Customer’s responsibility to retrieve a copy of the Customer Data within thirty (60) days of the date on which termination of the HyBird Ltd.
                      Terms &amp; Conditions February 2020 Contract is effective, following which date HyBird Ltd. is entitled to delete the Customer Data without notice.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Privacy</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      HyBird is committed to protecting Your privacy. HyBird&#39;s privacy policy governs the collection and use of any personal information You provide to HyBird and forms an integral
                      part of the General Terms. By using HyBird services or platform, you acknowledge that you have read and understood the privacy policy.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Marketing and Publicity</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      The Customer agrees that HyBird Ltd. may refer to the Customer as being a user of the HyBird Ltd. Services in its marketing materials, press releases, or on its website.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Notices and Consents</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      Any notice or consent from one party to the other party under the Contract must be given by email using, in the case of HyBird Ltd., {' '}
                      <a className="standard-link" href={`mailto:${HYBIRD_DATA.email}`}>{`${HYBIRD_DATA.email}`}</a>
                      or, in the case of the Customer, the email address set out in the Subscription.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Subcontracting</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      HyBird Ltd. may subcontract any of its obligations under the Contract, provided that HyBird Ltd. shall remain responsible to the Customer for the performance of any subcontracted
                      obligations.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Assignment</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      The Customer must not assign, transfer or otherwise deal with its contractual rights and/or obligations under the Contract without the prior written consent of HyBird Ltd., such
                      consent not to be unreasonably withheld or delayed.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">No waivers</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">No breach of any provision of the Contract will be waived except with the express written consent of the party not in breach.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      No waiver of any breach of any provision of the Contract shall be construed as a further or continuing waiver of any breach of that provision or any other provision of the
                      Contract.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Severability</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      If a provision of the Contract is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      If any unlawful and/or unenforceable provision of the Contract would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of
                      the provision will continue in effect.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Third party rights</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">The Contract is for the benefit of the parties, and is not intended to benefit or be enforceable by any third party.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Variation</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">The Contract may not be varied except in accordance with this clause 26.</p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      Subject to clause 26.3, the Contract may be varied by means of a written document signed, including by electronic signature, by or on behalf of each party.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      HyBird Ltd. may from time to time make changes to the Specification, provided that any such change shall not adversely affect the core functionality of the HyBird Ltd. Services
                      in any material respect.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Entire agreement</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      The Contract shall constitute the entire agreement between the parties in relation to its subject matter, and shall supersede all previous agreements, arrangements and
                      understandings between the parties in respect of that subject matter.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      Neither party will have any remedy in respect of any misrepresentation (whether written or oral) made to it upon which it relied in entering into the Contract.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">The provisions of this clause 27 are subject to clause 14.1.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="f-primary bold">Law and jurisdiction</p>
                <ol className="sub-1">
                  <li>
                    <p className="f-tertiary">
                      The Contract and all disputes or claims arising out of or in connection with the Contract or its subject matter or formation (including non-contractual disputes or claims) shall
                      be governed by and construed in accordance with English law.
                    </p>
                  </li>
                  <li>
                    <p className="f-tertiary">
                      Any disputes relating to the Contract or its subject matter or formation (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the
                      courts of England.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>,
          ],
        ]}
        className="usual-banner first-banner"
      />
    </div>
  );
};

Privacy.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Privacy;
