export default [
  {
    id: 1,
    lat: 51.549700941548146,
    lng: -0.14346596219767804,
    title: 'London, UK',
  },
  {
    id: 2,
    lat: 43.85507473192225,
    lng: 18.399574686356765,
    title: 'Sarajevo, Bosnia',
  },
  {
    id: 3,
    lat: 6.505224019749233,
    lng: 3.3737251485220696,
    title: 'Lagos, Nigeria',
  },
  {
    id: 4,
    lat: 25.762481007382288,
    lng: -80.19056005200086,
    title: 'Miami, USA',
  },
  {
    id: 4,
    lat: 25.21365840708933,
    lng: 55.28249964501922,
    title: 'Dubai, UE',
  },
];
