import React from 'react';
import { Router, Route, IndexRoute, browserHistory, Redirect } from 'react-router';
import { values } from 'lodash';

import routesConstants from '../common/routes-constants';

import App from '../App';

const Routes = () => {
  const renderRoutes = routes => {
    if (routes && routes.length > 0) {
      return routes.map(({ path, component }, index) => {
        return (
          <Route
            key={index}
            {...{
              path,
              component,
            }}
          />
        );
      });
    } else return null;
  };

  return (
    <Router history={browserHistory}>
      <Route path="/" component={App}>
        <IndexRoute component={routesConstants.routes.unProtectedRoutes.home.component} />
        {renderRoutes(values(routesConstants.routes.unProtectedRoutes))}
        <Redirect from="*" to={routesConstants.routes.unProtectedRoutes.home.path} />
      </Route>
    </Router>
  );
};

export default Routes;

export const history = browserHistory;
