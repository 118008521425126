import React from 'react';
import PropTypes from 'prop-types';

import MainBanner from '../../shared/main-banner/components/main-banner';
import Button from '../../../common/form/components/button';
// import Crumbs from '../../shared/crumbs/components/crumbs';
import Stepper from '../../shared/stepper/components/stepper';

import copyLink from '../assets/copy-link.png';

import mainImage from '../assets/DJI_0009 1.png';
import mainImage2 from '../assets/main-image-2.png';
import mainImage3 from '../assets/image-11.png';

import Helpers from '../../../core/helpers';
import routesConstants from '../../../common/routes-constants';

import { THEMES, CONTACT_US_TYPES, SOCIAL_MEDIA_ICONS } from '../../../common/constants';

// import { stepperMatrix } from '../../3drm/constants/three-drm';

import fourthBanner1 from '../assets/Subtract.svg';
import fourthBanner2 from '../assets/Subtract-1.svg';
import fourthBanner3 from '../assets/Subtract-2.svg';
import fourthBanner4 from '../assets/Subtract-3.svg';
import fourthBanner5 from '../assets/Subtract-4.svg';

import '../styles/case-study.scss';

const CaseStudy = (props, { t }) => {
  return (
    <div className="case-study-container">
      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <div className="title-wrapper text-margin">
              <div className="social-media upper">
                <div className="social-links">
                  {SOCIAL_MEDIA_ICONS.map(({ link, icon }, index) => {
                    return (
                      <a href={link} target="_blank" rel="noopener noreferrer" key={index} className="single-item pointer">
                        <img src={icon} alt="" className="pointer" />
                      </a>
                    );
                  })}
                  <div className="single-item">
                    <img className="pointer copy-link" src={copyLink} alt="" onClick={() => Helpers.copyText(window.location.href)} />
                  </div>
                </div>
              </div>
              <div className="text">
                <p className="f-tertiary">{t('CASE_STUDY.TEXT_1')}</p>
                <h3 className="f-primary bold text-margin">{t('CASE_STUDY.TEXT_2')}</h3>
              </div>
              <Button
                type="button"
                className="btn-small btn-dark heading-margin col-md-12"
                onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.contactUs.fullPath, [{ rt: CONTACT_US_TYPES.request.type }])}
              >
                <p className="f-secondary">{t('CASE_STUDY.FIRST_BANNER.BTN_TEXT')}</p>
              </Button>
            </div>,
          ],
          [<img className="second-image" src={mainImage} alt="" />],
        ]}
        className="usual-banner first-banner"
      />
      <MainBanner
        upperItems={
          <div className="upper">
            <h3 className="f-primary bold heading-margin"> {t('CASE_STUDY.TEXT_4')}</h3>

            <div className="social-media lower">
              <p className="f-tertiary noselect">{t('CASE_STUDY.SHARE')}</p>
              <div className="social-links">
                {SOCIAL_MEDIA_ICONS.map(({ link, icon }, index) => {
                  return (
                    <a href={link} target="_blank" rel="noopener noreferrer" key={index} className="single-item pointer">
                      <img src={icon} alt="" className="pointer" />
                    </a>
                  );
                })}
                <div className="single-item">
                  <img className="pointer copy-link" src={copyLink} alt="" onClick={() => Helpers.copyText(window.location.href)} />
                </div>
              </div>
            </div>
          </div>
        }
        classDefinitions={['col-md-12 col-sm-12']}
        items={[
          [
            <>
              <div className="with-line">
                <p className="f-green text-margin italic">{t('CASE_STUDY.TEXT_5')}</p>
                <p className="f-primary bold text-margin italic">{t('CASE_STUDY.TEXT_6')}</p>
                <p className="f-tertiary text-margin italic">{t('CASE_STUDY.TEXT_7')}</p>
                <p className="f-tertiary text-margin italic">{t('CASE_STUDY.TEXT_8')}</p>
              </div>
            </>,
          ],
        ]}
        className="usual-banner fifth-banner"
      />

      <MainBanner
        classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
        items={[
          [
            <MainBanner
              classDefinitions={['col-md-12 col-sm-12']}
              items={[
                [
                  <>
                    <h3 className="f-primary bold heading-margin"> {t('CASE_STUDY.TEXT_9_TITLE')}</h3>
                    <div className="with-line">
                      <p className="f-tertiary text-margin italic" dangerouslySetInnerHTML={{ __html: t('CASE_STUDY.TEXT_9') }} />
                    </div>
                  </>,
                ],
              ]}
              className="usual-banner fifth-banner"
            />,
          ],
          [
            <MainBanner
              classDefinitions={['col-md-12 col-sm-12', 'col-md-12 col-sm-12']}
              items={[
                [
                  <>
                    <div className="image-group">
                      <div className="single-img main-image">
                        <img src={mainImage2} alt="" />
                        <div className="text-group global-side-padding">
                          <h3 className="f-primary bold heading-margin">{t('CASE_STUDY.TEXT_10')}</h3>
                          <ul className="text-margin ">
                            <li className="f-primary" dangerouslySetInnerHTML={{ __html: t('CASE_STUDY.TEXT_11') }} />
                            <li className="f-primary" dangerouslySetInnerHTML={{ __html: t('CASE_STUDY.TEXT_12') }} />
                            <li className="f-primary" dangerouslySetInnerHTML={{ __html: t('CASE_STUDY.TEXT_13') }} />
                            <li className="f-primary" dangerouslySetInnerHTML={{ __html: t('CASE_STUDY.TEXT_14') }} />
                            <li className="f-primary" dangerouslySetInnerHTML={{ __html: t('CASE_STUDY.TEXT_15') }} />
                          </ul>
                        </div>
                      </div>
                      <div className="single-img second-image">
                        <img src={mainImage3} alt="" />
                      </div>
                    </div>
                  </>,
                ],
              ]}
              className="usual-banner sixth-banner"
            />,
          ],
          [],
        ]}
        className="usual-banner group-banner"
      />

      <MainBanner
        upperItems={<h3 className="f-primary bold heading-margin heading">{t('CASE_STUDY.TEXT_16')}</h3>}
        items={[
          [
            <div className="single-item-wrapper">
              <div className="single-item">
                <img src={fourthBanner1} alt="" />
                <div className="text">
                  <p className="f-primary bold ">{t('CASE_STUDY.TEXT_17')}</p>
                  <p className="f-tertiary mt">{t('CASE_STUDY.TEXT_18')}</p>
                </div>
              </div>
            </div>,
            <div className="single-item-wrapper">
              <div className="single-item">
                <img src={fourthBanner4} alt="" />
                <div className="text">
                  <p className="f-primary bold ">{t('CASE_STUDY.TEXT_21')}</p>
                  <p className="f-tertiary mt">{t('CASE_STUDY.TEXT_22')}</p>
                </div>
              </div>
            </div>,
            <div className="single-item-wrapper">
              <div className="single-item">
                <img src={fourthBanner5} alt="" />
                <div className="text">
                  <p className="f-primary bold ">{t('CASE_STUDY.TEXT_25')}</p>
                  <p className="f-tertiary mt">{t('CASE_STUDY.TEXT_26')}</p>
                </div>
              </div>
            </div>,
            <div className="single-item-wrapper">
              <div className="single-item">
                <img src={fourthBanner2} alt="" />
                <div className="text">
                  <p className="f-primary bold ">{t('CASE_STUDY.TEXT_19')}</p>
                  <p className="f-tertiary mt">{t('CASE_STUDY.TEXT_20')}</p>
                </div>
              </div>
            </div>,
            <div className="single-item-wrapper">
              <div className="single-item">
                <img src={fourthBanner3} alt="" />
                <div className="text">
                  <p className="f-primary bold ">{t('CASE_STUDY.TEXT_23')}</p>
                  <p className="f-tertiary mt">{t('CASE_STUDY.TEXT_24')}</p>
                </div>
              </div>
            </div>,
            <div className="single-item-wrapper">
              <div className="single-item green">
                <div className="text">
                  <p className="f-tertiary">{t('CASE_STUDY.TEXT_27')}</p>
                  <h4 className="f-primary bold mt">{t('CASE_STUDY.BUTTON_SECTION.BUTTON_1')}</h4>
                  <p className="f-tertiary mt">{t('CASE_STUDY.BUTTON_SECTION.BUTTON_1_TEXT')}</p>
                  <div className="buttons">
                    <Button
                      type="button"
                      className="btn-small btn-dark"
                      onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.contactUs.fullPath, [{ rt: CONTACT_US_TYPES.schedule.type }])}
                    >
                      <p className="f-secondary">{t('CASE_STUDY.BUTTON_SECTION.BUTTON_12')}</p>
                    </Button>
                  </div>
                </div>
              </div>
            </div>,
          ],
        ]}
        className="usual-banner second-banner"
      />
      <MainBanner
        upperItems={
          <div className="heading-wrapper">
            <h3 className="f-secondary heading bold heading-margin">{t('CASE_STUDY.TEXT_28')}</h3>
            <div className="line" />
          </div>
        }
        items={[
          [
            <div className="icons">
              <div className="single-item">
                <h1 className="f-secondary title">{t('CASE_STUDY.ICON_TEXT_1_M')}</h1>
                <p className="f-tertiary text-margin"> {t('CASE_STUDY.ICON_TEXT_1')}</p>
              </div>
              <div className="line" />

              <div className="single-item">
                <h1 className="f-secondary title">{t('CASE_STUDY.ICON_TEXT_2_M')}</h1>
                <p className="f-tertiary text-margin"> {t('CASE_STUDY.ICON_TEXT_2')}</p>
              </div>
              <div className="line" />
              <div className="single-item">
                <h1 className="f-secondary title">{t('CASE_STUDY.ICON_TEXT_3_M')}</h1>
                <p className="f-tertiary text-margin "> {t('CASE_STUDY.ICON_TEXT_3')}</p>
              </div>
            </div>,
          ],
        ]}
        className="usual-banner third-banner"
      />
      <MainBanner
        upperItems={<h3 className="f-primary bold heading-margin main-title">{t('CASE_STUDY.TEXT_3')}</h3>}
        items={[
          [
            <div className="stepper-wrapper">
              <Stepper
                className="text-margin"
                stepperItems={[
                  {
                    width: '10%',
                    color: THEMES.standard.stepperColorLight,
                    label: '',
                    backgroundImage: `linear-gradient(to right, ${THEMES.standard.primaryThemeColor} , ${THEMES.standard.stepperColorLight})`,
                  },
                  {
                    width: '15%',
                    color: THEMES.standard.stepperColorLight,
                    desc: t('CASE_STUDY.STEPPER_ITEM_1.DESC_1'),
                    subDesc: t('CASE_STUDY.STEPPER_ITEM_1.DESC_2'),
                    components: [<h1 className="f-secondary number">1</h1>],
                  },
                  {
                    width: '15%',
                    color: THEMES.standard.stepperColorMedium,
                    desc: t('CASE_STUDY.STEPPER_ITEM_2.DESC_1'),
                    subDesc: t('CASE_STUDY.STEPPER_ITEM_2.DESC_2'),
                    components: [<h1 className="f-secondary number">2</h1>],
                  },
                  {
                    width: '20%',
                    color: THEMES.standard.stepperColorSemiDark,
                    desc: t('CASE_STUDY.STEPPER_ITEM_3.DESC_1'),
                    subDesc: t('CASE_STUDY.STEPPER_ITEM_3.DESC_2'),
                    components: [<h1 className="f-secondary number">3</h1>],
                  },
                  {
                    width: '30%',
                    color: THEMES.standard.stepperColorDark,
                    desc: t('CASE_STUDY.STEPPER_ITEM_4.DESC_1'),
                    subDesc: t('CASE_STUDY.STEPPER_ITEM_4.DESC_2'),
                    components: [<h1 className="f-secondary number">4</h1>],
                  },
                  {
                    width: '10%',
                    color: THEMES.standard.stepperColorDark,
                    label: '',
                    backgroundImage: `linear-gradient(to left, ${THEMES.standard.primaryThemeColor}, ${THEMES.standard.stepperColorDark})`,
                  },
                ]}
              />
              <div className="text-below">
                <p className="f-tertiary">{t('CASE_STUDY.TEXT_27')}</p>
                <h3 className="f-primary bold text-margin-s"> {t('CASE_STUDY.BUTTON_SECTION.BUTTON_2')}</h3>
                <p className="f-primary  text-margin-s"> {t('CASE_STUDY.BUTTON_SECTION.BUTTON_2_TEXT')}</p>
                <div className="buttons heading-margin">
                  <Button
                    type="button"
                    className="btn-small btn-green "
                    onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.contactUs.fullPath, [{ rt: CONTACT_US_TYPES.speak.type }])}
                  >
                    <p className="f-secondary">{t('CASE_STUDY.BUTTON_SECTION.BUTTON_12')}</p>
                  </Button>
                </div>
              </div>
            </div>,
          ],
        ]}
        className="usual-banner seventh-banner"
      />
    </div>
  );
};

CaseStudy.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CaseStudy;
