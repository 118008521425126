import React, { useState } from 'react';

import Menu from './menu';
import Fade from 'react-reveal/Fade';
import BurgerMenu from './burger-menu';

import { ReactComponent as Logo } from '../../../../common/assets/menu-logo.svg';

import { menuItems } from '../constants/menu-constants';
import routesConstants from '../../../../common/routes-constants';

import Helpers from '../../../../core/helpers';

import '../style/header.scss';

const Header = ({ headerVisible, activeRoute }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className={`header-container ${headerVisible ? 'scrolled-up' : 'scrolled-down'}`}>
      <div className={`header-wrapper`}>
        <div
          className="logo-container"
          onClick={() => {
            setMenuOpened(false);
            Helpers.goTo(routesConstants.routes.unProtectedRoutes.home);
          }}
        >
          <Fade>
            <Logo className=" pointer" />
          </Fade>
        </div>

        <>
          <BurgerMenu
            open={menuOpened}
            events={{
              onClick: () => setMenuOpened(!menuOpened),
            }}
            direction="center"
          />
          <Menu
            {...{
              menuItems,
              activeRoute,
              onClick: item => {
                setMenuOpened(false);
                Helpers.goTo(item.path);
              },
            }}
          />
        </>
      </div>
    </div>
  );
};
export default Header;
