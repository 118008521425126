import crumb from '../assets/crumb.png';

export const fields = {
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'Email',
  message: 'Message',
  additionalDetails: 'AdditionalDetails',
  companyName: 'CompanyName',
  role: 'Role',
  sector: 'Sector',
  howDidYouHearAboutUs: 'HowDidYouHearAboutUs',
  interest: 'Interest',
  privacy: 'Privacy',
  marketingUpdates: 'MarketingUpdates',
};

export const whitePaperImageMatrix = [
  [{ width: 150, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10 }, { width: 5, height: 5, image: crumb }],
  [{ width: 90, height: 30, backgroundColor: 'transparent' }],
  [{ width: 120, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10, image: crumb }],
  [{ width: 60, height: 20, backgroundColor: 'transparent' }],
  [{ width: 80, height: 10, backgroundColor: 'transparent' }, { width: 20, height: 20, image: crumb }],
  [{ width: 60, height: 10, backgroundColor: 'transparent' }, { width: 40, height: 20 }],
  [{ width: 60, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10 }],

  [{ width: 20, height: 20, backgroundColor: 'transparent' }],
  [{ width: 20, height: 30, backgroundColor: 'transparent' }, { width: 20, height: 20 }],
  [{ width: 5, height: 5 }],
];

export const interestOptions = [
  {
    value: 'WHITE_PAPER_FORM.INTEREST_OPT_1',
    label: 'WHITE_PAPER_FORM.INTEREST_OPT_1',
  },
  {
    value: 'WHITE_PAPER_FORM.INTEREST_OPT_2',
    label: 'WHITE_PAPER_FORM.INTEREST_OPT_2',
  },
  {
    value: 'WHITE_PAPER_FORM.INTEREST_OPT_3',
    label: 'WHITE_PAPER_FORM.INTEREST_OPT_3',
  },
];
