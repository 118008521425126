import { browserHistory } from 'react-router';
import { isEmpty } from 'lodash';

class Helpers {
  static goTo = (path, params, data = null) => {
    if (!isEmpty(params)) {
      browserHistory.push({ pathname: path, search: this.generateQueryParams(params), state: data });
    } else {
      browserHistory.push(path);
    }
  };

  static generateQueryParams = params => {
    if (params.length === 0) return '';
    let query = '?';
    params.forEach((param, index) => {
      const key = Object.keys(param)[0];
      if (param[key] || !isNaN(param[key])) {
        query += `${key}=${param[key] + (index === params.length - 1 ? '' : '&')}`;
      }
    });
    return query;
  };

  static fallbackCopyTextToClipboard = text => {
    const el = document.createElement('textarea');
    el.value = text;

    el.style = 'visibility: hidden';
    document.body.appendChild(el);
    el.focus();
    el.select();
    el.setSelectionRange(0, 99999); /* For mobile devices */

    document.execCommand('copy');

    document.body.removeChild(el);
  };

  static copyText = text => {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function() {
        console.log('Async: Copying to clipboard was successful!');
      },
      function(err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };
}

export default Helpers;
