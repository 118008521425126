import routesConstants from '../../../../common/routes-constants';

export const menuItems = [
  {
    name: 'home',
    path: routesConstants.routes.unProtectedRoutes.home.fullPath,
    slug: 'MENU.HOME',
  },
  {
    name: 'clarity',
    path: routesConstants.routes.unProtectedRoutes.clarity.fullPath,
    slug: 'MENU.CLARITY',
  },
  {
    name: '3DRMGenration',
    path: routesConstants.routes.unProtectedRoutes.threeDRM.fullPath,
    slug: 'MENU.3DRM',
  },
  {
    name: 'caseStudy',
    path: routesConstants.routes.unProtectedRoutes.caseStudy.fullPath,
    slug: 'MENU.CASE_STUDY',
  },
  {
    name: 'contact',
    path: routesConstants.routes.unProtectedRoutes.contactUs.fullPath,
    slug: 'MENU.CONTACT',
  },
  {
    name: 'clarity',
    path: routesConstants.routes.unProtectedRoutes.whitePaper.fullPath,
    slug: 'MENU.WHITE_PAPER',
  },
];
