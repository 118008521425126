import part1 from '../assets/part-1.png';
import part2 from '../assets/part-2.png';
import part3 from '../assets/part-3.png';
import part4 from '../assets/part-4.png';
import part5 from '../assets/part-5.png';
import part6 from '../assets/part-6.png';
import { THEMES } from '../../../common/constants';

export const secondBannerTopMatrix = [
  ['.', { width: 10, height: 10, backgroundColor: THEMES.standard.banner2LightGreen }],
  [{ width: 20, height: 10, backgroundColor: 'transparent' }, { width: 20, height: 20, backgroundColor: THEMES.standard.banner2LightGreen }],
  [{ width: 20, height: 10, backgroundColor: 'transparent' }, { width: 35, height: 15, backgroundColor: THEMES.standard.banner2LightGreen }],
  [
    { width: 20, height: 15, backgroundColor: 'transparent' },
    { width: 20, height: 15, backgroundColor: THEMES.standard.banner2LightGreen },
    { width: 20, height: 15, backgroundColor: THEMES.standard.secondaryThemeColor },
    { width: 20, height: 15, backgroundColor: 'transparent' },
    { width: 10, height: 15, backgroundColor: THEMES.standard.secondaryThemeColor },
  ],
  [{ width: 40, height: 10, backgroundColor: 'transparent' }, { width: 20, height: 10, backgroundColor: THEMES.standard.secondaryThemeColor }],

  [{ width: 60, height: 10, backgroundColor: 'transparent' }, { width: 20, height: 20, backgroundColor: THEMES.standard.secondaryThemeColor }],
  ['.'],
  ['.'],
  [{ width: 120, height: 10, backgroundColor: 'transparent' }, { width: 10, height: 10, backgroundColor: THEMES.standard.secondaryThemeColor }],
  ['.'],
  ['.'],
  [{ width: 160, height: 10, backgroundColor: 'transparent' }, { width: 7, height: 7, backgroundColor: THEMES.standard.secondaryThemeColor }],
];

export const secondBannerBottomMatrix = [
  [{ width: 40, height: 7, backgroundColor: 'transparent' }, { width: 7, height: 7 }],
  [{ width: 40, height: 7, backgroundColor: 'transparent' }, { width: 13, height: 10 }],
  [{ width: 40, height: 7, backgroundColor: 'transparent' }, { width: 20, height: 25 }],
  [{ width: 8, height: 10 }, { width: 13, height: 13, image: part4 }, { width: 20, height: 20, image: part1 }],
  [{ width: 20, height: 20 }, { width: 20, height: 20, image: part2 }, { width: 20, height: 20, backgroundColor: 'transparent' }, { width: 20, height: 20 }],
  [{ width: 40, height: 20 }, { width: 20, height: 20, image: part3 }],
  [{ width: 15, height: 15, image: part5 }],
  ['.'],
  ['.'],
  [{ width: 10, height: 10, image: part6 }],
  ['.'],
];

//blog banner matrix
export const blogBannerMatrixBottom = [
  [{ width: 6, height: 6 }],
  [{ width: 20, height: 50, backgroundColor: 'transparent' }],
  [{ width: 150, height: 6, backgroundColor: 'transparent' }, { width: 6, height: 6 }],
  [{ width: 20, height: 10, backgroundColor: 'transparent' }],
  [{ width: 60, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 20, height: 30, backgroundColor: 'transparent' }],
  [{ width: 100, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 175, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 205, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 220, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }, { width: 15, height: 15 }],
];

export const blogBannerMatrixTop = [
  [{ width: 60, height: 15 }],
  [{ width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }, { width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 45, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }, { width: 15, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 170, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 170, height: 30, backgroundColor: 'transparent' }],
  [{ width: 190, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15 }],
  [{ width: 170, height: 10, backgroundColor: 'transparent' }],
  [{ width: 90, height: 10, backgroundColor: 'transparent' }, { width: 6, height: 6 }],
  [{ width: 170, height: 40, backgroundColor: 'transparent' }],
  [{ width: 280, height: 10, backgroundColor: 'transparent' }, { width: 6, height: 6 }],
];

export const sixthBannerTopMatrix = [
  ['.', { width: 10, height: 10, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 10, height: 20, backgroundColor: 'transparent' }],
  [{ width: 55, height: 15 }, { width: 15, height: 15, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 10, height: 60, backgroundColor: 'transparent' }],
  [{ width: 20, height: 15 }, { width: 20, height: 20, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 40, height: 13, backgroundColor: 'transparent' }, { width: 25, height: 13, backgroundColor: 'transparent' }, { width: 13, height: 13 }],
  [{ width: 40, height: 12, backgroundColor: 'transparent' }, { width: 25, height: 13, backgroundColor: 'transparent' }],
  ['.'],
  [{ width: 75, height: 12, backgroundColor: 'transparent' }, { width: 12, height: 12 }],
  [{ width: 88, height: 25, backgroundColor: 'transparent' }, { width: 25, height: 25 }],
  [{ width: 185, height: 25, backgroundColor: 'transparent' }, { width: 12, height: 12 }],
];
export const sixthBannerBottomMatrix = [
  [{ width: 200, height: 12, backgroundColor: 'transparent' }, { width: 12, height: 12 }],
  [{ width: 92, height: 25, backgroundColor: 'transparent' }, { width: 25, height: 25 }],
  [{ width: 80, height: 12, backgroundColor: 'transparent' }, { width: 12, height: 12 }],
  [{ width: 12, height: 12, backgroundColor: 'transparent' }],
  [{ width: 40, height: 12, backgroundColor: 'transparent' }, { width: 25, height: 12 }],
  [{ width: 40, height: 12, backgroundColor: 'transparent' }, { width: 25, height: 12 }, { width: 12, height: 12 }],
  [{ width: 20, height: 20, backgroundColor: 'transparent' }, { width: 20, height: 20, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 5, height: 45, backgroundColor: 'transparent' }],
  [{ width: 40, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 25, height: 25, backgroundColor: 'transparent' }],
  ['.', { width: 10, height: 10, backgroundColor: THEMES.standard.secondaryThemeColor }],
];

export const fourthBannerMatrix = [
  // [{ width: 200, height: 12, backgroundColor: 'transparent' }, { width: 12, height: 12 }],
  // [{ width: 92, height: 25, backgroundColor: 'transparent' }, { width: 25, height: 25 }],
  // [{ width: 80, height: 12, backgroundColor: 'transparent' }, { width: 12, height: 12 }],
  // [{ width: 12, height: 12, backgroundColor: 'transparent' }],
  // [{ width: 40, height: 12, backgroundColor: 'transparent' }, { width: 25, height: 12 }],
  // [{ width: 40, height: 12, backgroundColor: 'transparent' }, { width: 25, height: 12 }, { width: 12, height: 12 }],
  // [{ width: 20, height: 20, backgroundColor: 'transparent' }, { width: 20, height: 20, backgroundColor: THEMES.standard.secondaryThemeColor }],
  // [{ width: 5, height: 45, backgroundColor: 'transparent' }],
  // [{ width: 40, height: 15, backgroundColor: 'transparent' }, { width: 15, height: 15, backgroundColor: THEMES.standard.secondaryThemeColor }],
  // [{ width: 25, height: 25, backgroundColor: 'transparent' }],
  ['.', { width: 20, height: 20, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 40, height: 13 }, { width: 13, height: 13, backgroundColor: THEMES.standard.secondaryThemeColor }],
  [{ width: 20, height: 30 }],
  [{ width: 8, height: 8, backgroundColor: THEMES.standard.secondaryThemeColor }],
];

export const blogLinks = [
  'https://medium.com/hybird/why-your-organisation-should-ditch-p-ids-right-now-part-1-d210d90e655b',
  'https://medium.com/hybird/why-your-organisation-should-ditch-p-ids-right-now-part-2-74f0aaa3cf8b',
  'https://medium.com/hybird',
];
